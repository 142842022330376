<template>
  <div id="landingpage"></div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
  name: "landing",
  data(): { isLoading: boolean } {
    return {
      isLoading: false,
    };
  },
  mounted: function () {
    setTimeout(() => {
      this.isLoading = true;
    }, 250);
    if (this.loggedInUser) {
      if (!this.hasActiveTeam) {
        this.$router.replace({ name: "teamSelection", query: { from: "landing" } });
      } else {
        this.$router.replace({ name: "modelSelection", query: { from: "landing" } });
      }
    } else {
      try {
        let hashParams = this.getHashParams();
        const id_token = hashParams.id_token;
        if (id_token) {
          this.$router.replace({ name: "openId", hash: "/#id_token=" + id_token });
        } else {
          throw "id_token not found";
        }
      } catch (error) {
        console.error(error);
        this.$router.replace({ name: "loginEmail", query: { from: "landing" } });
      }
    }
  },
  computed: {
    ...mapState("login", {
      loggedInUser: "user",
    }),
    ...mapState("team", {
      hasActiveTeam: "activeTeam",
    }),
    ...mapState("model", {
      hasActiveModel: "activeModel",
    }),
  },
  methods: {
    getHashParams() {
      let hashParams: any = [];
      let e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&;=]+)=?([^&;]*)/g,
        d = function (s: string) {
          return decodeURIComponent(s.replace(a, " "));
        },
        q = window.location.hash.substring(1);

      while ((e = r.exec(q))) hashParams[d(e[1])] = d(e[2]);

      return hashParams;
    },
  },
};
</script>

<style lang="scss">
#landingpage {
  height: 50vh;
  padding: 10% 0 0 0;
}
</style>
