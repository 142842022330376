import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { IRootState } from "@/store";
import api from "./apiCalls";
import { useDiscover } from "@/stores/discover/store";
const regionCountryMap = require("../discover/targetAccounts/regionCountryMap.json");

const getDefaultState = () => {
  return {
    accounts: undefined,
    segments: undefined,
    selectedFilterData: undefined,
    segmentData: undefined,
    products: undefined,
    categories: undefined,
    vendors: undefined,
    segmentFilters: undefined,
    savedDatasets: [],
    payload: undefined,
  };
};

export const state: ISegmentState = getDefaultState();

export interface ISegmentState {
  accounts?: IAccounts;
  segments?: ISegments;
  segmentData?: ISegmentData;
  selectedFilterData?: object;
  products?: any[];
  categories?: any[];
  vendors?: any[];
  segmentFilters?: any[];
  savedDatasets?: any[];
  payload: any;
}

export interface IAccounts {
  accounts: object;
  num_accounts?: number;
}

export interface ISegmentData {
  [key: string]: any;
}

export interface ISegments {
  segments: any[];
}

export interface ITags {
  count: number;
  creation_time: any;
  tag_name: string;
}

const actions: ActionTree<ISegmentState, IRootState> = {
  resetSegmentState({ commit }) {
    commit("resetState");
  },
  getSegmentAutocomplete(store: ActionContext<ISegmentState, any>, payload: { modelId: string; teamId: string; groupFilters?: boolean }): Promise<ISegments> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getSegmentAutocomplete error", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        if (data && Array.isArray(data)) {
          const discoverStore = useDiscover();
          discoverStore.$reset();
          const techArray: Array<string> = ["products", "vendors", "categories"];
          techArray.forEach((type: any) => {
            const list: any = data.find((item) => item.name === type);
            if (list && list.hasOwnProperty("autocomplete") && Array.isArray(list.autocomplete)) {
              store.commit("SetAutocomplete", { type, data: list.autocomplete });
            }
          });
          let tempData: any = data;
          const vendorIndex = data.findIndex((item: any) => item.name === "vendors");
          tempData.splice(vendorIndex, 1);
          const categoryIndex = data.findIndex((item: any) => item.name === "categories");
          tempData.splice(categoryIndex, 1);

          if (payload.groupFilters) {
            const reducedData: any = [];
            tempData.forEach(function (filter: any) {
              const labels: any = { industry: "Industry name", naics_code: "NAICS code" };
              const existingIndex = reducedData.findIndex((item: any) => item.label === filter.label);
              if (existingIndex < 0) {
                reducedData.push(filter);
              } else {
                const name = reducedData[existingIndex].name;
                const existingAutocomplete = reducedData[existingIndex].autocomplete.map((v: any) =>
                  Object.assign(v, {
                    name: name,
                    parentLabel: reducedData[existingIndex].label,
                  }),
                );
                reducedData[existingIndex]["isGroup"] = true;
                reducedData[existingIndex].autocomplete = [];
                reducedData[existingIndex].autocomplete.push({
                  groupLabel: labels[name] ? labels[name] : reducedData[existingIndex].name,
                  group: reducedData[existingIndex].name,
                  name: reducedData[existingIndex].label,
                  autocomplete: existingAutocomplete,
                });
                reducedData[existingIndex].autocomplete.push({
                  groupLabel: labels[filter.name] ? labels[filter.name] : filter.name,
                  group: filter.name,
                  autocomplete: filter.autocomplete.map((v: any) => Object.assign(v, { name: filter.name, parentLabel: filter.label })),
                  name: filter.label,
                });
              }
            });
            tempData = [...reducedData];
          }

          const index = data.findIndex((item: any) => item.name === "location");
          if (index >= 0 && tempData && tempData[index] && tempData[index].hasOwnProperty("autocomplete") && Array.isArray(tempData[index].autocomplete)) {
            tempData[index].autocomplete.sort();
            for (const region in regionCountryMap) {
              tempData[index].autocomplete.push({ label: region, value: region });
            }
          }

          const filterData: ISegments = tempData as ISegments;
          store.commit("SegmentFilters", filterData);
          resolve(filterData);
          return;
        }
        console.error("getSegmentAutocomplete error", "response is not a segment", JSON.stringify(data, null, 2));
        reject(new Error("invalid response"));
      };

      if (!payload.modelId || !payload.teamId) {
        errorHandler({ error: "empty parameters for getSegmentAutocomplete API call" });
        return;
      }

      api.getSegmentAutocomplete(payload.modelId, payload.teamId).then(successHandler).catch(errorHandler);
    });
  },
  saveSegment(store: ActionContext<ISegmentState, any>, payload: { modelId: string; teamId: string; spec: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("saveSegment error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("saveSegment error", "response is not an object", data);
        reject(new Error("invalid response"));
      };

      if (!payload.modelId || !payload.teamId) {
        errorHandler({ error: "empty parameters for saveSegment API call" });
        return;
      }
      api.saveSegment(payload.modelId, payload.teamId, payload.spec).then(successHandler).catch(errorHandler);
    });
  },

  patchSegment(store: ActionContext<ISegmentState, any>, payload: { modelId: string; teamId: string; segmentId: string; patchData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("patchSegment error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("patchSegment error", "response is not an object", data);
        reject(new Error("invalid response"));
      };

      if (!payload.modelId || !payload.patchData || !payload.segmentId) {
        errorHandler({ error: "empty parameters for patchSegment API call" });
        return;
      }
      api.patchSegment(payload.modelId, payload.teamId, payload.segmentId, payload.patchData).then(successHandler).catch(errorHandler);
    });
  },
  deleteSegment(
    store: ActionContext<ISegmentState, any>,
    payload: {
      modelId: string;
      teamId: string;
      segmentId: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("deleteSegment error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        resolve(data);
      };
      if (!payload.modelId || !payload.segmentId) {
        errorHandler({ error: "empty parameters for deleteSegment API call" });
        return;
      }
      api.deleteSegment(payload.modelId, payload.teamId, payload.segmentId).then(successHandler).catch(errorHandler);
    });
  },
  setSavedDatasets(store: ActionContext<ISegmentState, any>, datasets: object): void {
    store.commit("SavedDatasets", datasets);
  },
};

const getters: GetterTree<ISegmentState, IRootState> = {
  getAutocompleteData(state: ISegmentState): any {
    const { products, categories, vendors } = state;

    const returnData: Array<{ type: string; result: any; total: number }> = [];
    returnData.push({
      type: "products",
      result: products,
      total: products ? products.length : 0,
    });
    returnData.push({
      type: "categories",
      result: categories,
      total: categories ? categories.length : 0,
    });
    returnData.push({
      type: "vendors",
      result: vendors,
      total: vendors ? vendors.length : 0,
    });
    return returnData;
  },
};

const mutations: MutationTree<ISegmentState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SegmentFilters(state: ISegmentState, segmentFilters: any): void {
    state.segmentFilters = segmentFilters;
  },
  SetAutocomplete(state: ISegmentState, payload: { type: string; data: any }): void {
    state[payload.type] = payload.data;
  },
  SavedDatasets(state: ISegmentState, datasets: any): void {
    state.savedDatasets = datasets;
  },
};

const namespaced: boolean = true;

export const segmentStore: Module<ISegmentState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
