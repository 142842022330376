import { IRootState } from "@/store";
import { ActionContext, ActionTree, Module } from "vuex";
import api from "./apiCalls";

export const state: IModelInsightState = {};

export interface IModelInsightState {}

const actions: ActionTree<IModelInsightState, IRootState> = {
  getModelSummary(store: ActionContext<IModelInsightState, any>, payload: { modelId: string; teamId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("retriveModel error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "missing parameters for getModelSummary API call" });
        return;
      }
      api.getModelSummary(payload.modelId, payload.teamId).then(successhandler).catch(errorHandler);
    });
  },
};

const namespaced: boolean = true;
export const modelInsightStore: Module<IModelInsightState, IRootState> = {
  namespaced,
  state,
  actions,
};
