import axios from "axios";
import utils from "@/shared/utils";

export default {
  getJWT(accountId: string, apiKey: string, teamId?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (accountId !== null && apiKey !== null) {
        const postData: {
          api_key: string;
          user_id: string;
          team_id?: string;
        } = {
          api_key: apiKey,
          user_id: accountId,
        };
        if (teamId) {
          postData["team_id"] = teamId;
        }
        axios
          .post(utils.MODEL_API + "v2/users/jwt/", postData)
          .then(utils.genericSuccessHandler(resolve, reject))
          .catch(utils.genericErrorHandler(resolve, reject));
      } else {
        reject(new Error("missing accountId or apiKey"));
      }
    });
  },
  revokeJWT(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/users/jwt/revoke")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  checkSSO(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const domain: string = email.split("@")[1].toLowerCase();
      axios
        .get(utils.MODEL_API + "organizations/" + domain)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  startSSO(email: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "organizations/openId/start/", { email })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  verifyIdToken(token: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "organizations/openId/authenticate/", { id_token: token })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  tryLogin(email: string, password: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          utils.API_STUB + "token",
          { email, password },
          {
            headers: {
              "content-type": "application/json",
            },
          },
        )
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.tokenErrorHandler(resolve, reject));
    });
  },
  resetPassword(email: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          utils.API_STUB + "request-password-token",
          {
            email,
            app: "mui",
          },
          {
            headers: {
              "content-type": "application/json",
            },
          },
        )
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  setPassword(tokenId: string, email: string, password: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          utils.API_STUB + "set-password",
          {
            token: tokenId,
            email,
            password,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          },
        )
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  verifyPasswordToken(tokenId: string, email: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          utils.API_STUB + "verify-password-token",
          {
            token_id: tokenId,
            email_address: email,
          },
          {
            headers: {
              "content-type": "application/json",
            },
          },
        )
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getUserTeams(userId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/users/" + userId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  signUpUser(spec: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/users/signup/", spec)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  verifyUser(spec: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/users/verify_email/", spec)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
