/* istanbul ignore file */
import axios from "axios";
import utils from "@/shared/utils";

export default {
  getCSAdmins(teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/teams/" + teamId + "/cs_admins/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  demoteCSAdmin(teamId: string, userId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "v2/teams/" + teamId + "/cs_admins/" + userId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  createCSAdmin(payload: any, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/teams/" + teamId + "/cs_admins/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  promoteCSAdmin(teamId: string, userId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/teams/" + teamId + "/cs_admins/" + userId + "/", { role: "enlyft_cs" })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getTenants(tenantId?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (tenantId) {
        axios
          .get(utils.MODEL_API + "admin/tenants/" + tenantId + "/")
          .then(utils.genericSuccessHandler(resolve, reject))
          .catch(utils.genericErrorHandler(resolve, reject));
      } else {
        axios
          .get(utils.MODEL_API + "admin/tenants/")
          .then(utils.genericSuccessHandler(resolve, reject))
          .catch(utils.genericErrorHandler(resolve, reject));
      }
    });
  },
  getTenantTeam(tenantId: string, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "admin/tenants/" + tenantId + "/teams/" + teamId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  createTenant(payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "admin/tenants/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  resetTeam(teamName: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "admin/tenants/reset_sales_team/" + teamName + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  createTargetingOffering(payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + payload.teamId + "/", {
          name: payload.name,
          description: payload.description,
          model_type: "targeting",
          parent_id: payload.parent_id,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  cancelSubscriptionRenewal(payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "/admin/subscriptions/" + payload.subscription_uuid + "/not_renewal", {})
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  cancelSubscriptionStatus(payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "/admin/subscriptions/" + payload.subscription_uuid + "/cancel", {})
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  changeSubscriptionPaymentStatus(payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "admin/subscriptions/" + payload.subscription_uuid + "/offline_payment/", {
          payment_type: "manual",
          payment_processor: "stripe",
          payment_processor_id: payload.stripeId,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  addCreditsToExistingOffering(payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "/admin/subscriptions/" + payload.subscription_uuid + "/add_extra_credits", {
          description: payload.description,
          tenantID: payload.tenant_id,
          nCredits: payload.nCredits,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  createUser(accountId: string, user_name: string, user_email: string, role: string, send_email: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/teams/" + accountId + "/users/", {
          email: user_email,
          name: user_name,
          role: role,
          send_email: send_email,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getProducts(): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "admin/payments/products" + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getPlans(productId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "admin/payments/products/plans/", {
          product_id: productId,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getInvoiceAmount(planId: string, couponId: string, licenses: number): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "admin/payments/amount_due/", {
          planid: planId,
          couponid: couponId,
          nLicenses: licenses,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getCoupons(): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "admin/payments/coupons" + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
