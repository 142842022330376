import { GENERIC_ERRORS } from "@/shared/constants/errorConstants";
import utils from "@/shared/utils";
import { AutoComplete } from "@/types/autocomplete";
import axios, { AxiosResponse } from "axios";

import { SavedAccounts } from "./types";

export async function getFilters({ modelId }: { modelId: string }): Promise<Array<AutoComplete>> {
  const url = `${utils.MODEL_API}v2/search/browse/filters/accounts/${modelId}/`;
  try {
    const response: AxiosResponse = await axios.get(url);

    if (response?.data?.status === "failure") {
      throw new Error(response.data.message);
    }

    if (!response.data?.data) {
      throw new Error(GENERIC_ERRORS.NO_FILTERS);
    }
    return response.data.data;
  } catch (error: any) {
    console.error("getFilters error", error);
    throw new Error(error?.message ? error.message : error);
  }
}

export async function getAccounts({ limit, skip, modelId, spec, signal }: SavedAccounts.AccountsParams): Promise<SavedAccounts.AccountsResponse> {
  try {
    const url = `${utils.MODEL_API}v2/search/browse/accounts/${modelId}/?limit=${limit}&skip=${skip}`;
    const response: AxiosResponse = await axios.post(url, { spec }, { signal });

    if (response?.data?.status === "failure") throw new Error(response.data.message);
    return response.data.data;
  } catch (error: any) {
    throw new Error(error?.message);
  }
}
