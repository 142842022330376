export const hostName = process.env.VUE_APP_EXTENSION_HOSTNAME || "";
export const endPoint = process.env.VUE_APP_EXTENSION_ENDPOINT || "";
export const cookieDomain = process.env.VUE_APP_EXTENSION_COOKIEDOMAIN || "";

// doesn't seem to be used anywhere
// const mpId = process.env.VUE_APP_EXTENSION_MPID || "";

export const isDev = process.env.VUE_APP_DEPLOY_MODE === "development";

if (isDev) {
  console.info("environment", { hostName, endPoint, cookieDomain });
}

export const isBrowserExtension = typeof window !== "undefined" && window?.location?.protocol === "chrome-extension:";
