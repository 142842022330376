<template>
  <div id="app-footer" v-bind:class="[layout && layout === 'empty' ? 'footer-empty' : 'footer']" v-if="showFooter">
    <span>&copy; {{ new Date().getFullYear() }} Enlyft | All rights reserved. <span v-if="showClearbitMsg"> | Logos provided by Clearbit </span> <span v-html="buildNumber" /></span>
    <span class="footer-span">.</span>
    <span class="footer-span">
      <a href="https://enlyft.com/privacy-policy/" target="_blank" v-bind:class="[layout && layout === 'empty' ? 'footerlink-empty' : 'footerlink']">Privacy</a>
      <span class="footer-span"></span>
      <a href="https://enlyft.com/terms-of-service/" target="_blank" v-bind:class="[layout && layout === 'empty' ? 'footerlink-empty' : 'footerlink']">Terms</a>
      <span class="footer-span"></span>
      <a href="https://enlyft.com/legal/" target="_blank" v-bind:class="[layout && layout === 'empty' ? 'footerlink-empty' : 'footerlink']">Legal</a>
    </span>
    <span class="footer-span">.</span>
    <span>
      <span class="footer-span">
        <a href="https://www.linkedin.com/company/enlyft/" target="_blank" aria-label="View Enlyft's LinkedIn Profile">
          <img :src="getSocialIcon('linkedin')" class="social-icon" alt="LinkedIn" />
        </a>
      </span>
      <span class="footer-span">
        <a href="https://twitter.com/enlyftinc" target="_blank" aria-label="View Enlyft's Twitter Profile">
          <img :src="getSocialIcon('twitter')" class="social-icon" alt="Twitter" />
        </a>
      </span>
      <span class="footer-span">
        <a href="https://www.facebook.com/EnlyftAI/" target="_blank" aria-label="View Enlyft's Facebook Profile">
          <img :src="getSocialIcon('fb')" class="social-icon" alt="Facebook" />
        </a>
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import { useShared } from "@/stores/shared/store";
import utils from "@/shared/utils";

export default {
  props: ["layout"],
  data(): {
    socialicon: string;
    fbUrl: string;
  } {
    return {
      socialicon: "",
      fbUrl: "@/shared/sidebaricons/icon-fb.svg",
    };
  },
  computed: {
    ...mapStores(useShared),
    buildNumber(): string {
      if (!utils || !utils.BUILD_VERSION || typeof utils.BUILD_VERSION !== "string" || utils.BUILD_VERSION.length < 10) return "";
      return " | v1." + utils.BUILD_VERSION.substr(3, 6);
    },
    showFooter(): boolean {
      return !!(this.$route.name !== "teamSelection");
    },
    showClearbitMsg(): boolean {
      return this.sharedStore.showClearbitMsg;
    },
  },
  mounted() {
    if (this.layout && this.layout === "empty") {
      this.socialicon = "-white";
    }
  },
  methods: {
    getSocialIcon(iconType: string): string {
      try {
        return require("./sidebaricons/icon-" + iconType + this.socialicon + ".svg");
      } catch (ex) {
        console.error("Error while getting social icon " + ex);
        return require("@/shared/filterUI/icon-blank.svg");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.footer-empty {
  position: absolute;
  padding: 4px, 0px, 4px, 0px;
  bottom: 0;
  width: auto;
  color: #fff;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25px;
  transition: all 0.2s ease-in-out;
}
.footer {
  flex-wrap: wrap;
  position: absolute;
  padding: 4px, 0px, 4px, 0px;
  padding-left: 208px;
  bottom: 0;
  width: auto;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25px;
  transition: all 0.2s ease-in-out;
  color: var(--body-secondary);
}
.narrowSideBar-footer {
  padding-left: var(--sidebar-narrow) !important;
}
.footer-span {
  padding-left: 8px;
}
.footerlink {
  color: var(--body-secondary) !important;
}
.footerlink:hover {
  color: var(--body-secondary) !important;
  text-decoration: none;
}
.footerlink-empty {
  color: #fff !important;
}
.footerlink-empty:hover {
  color: #fff !important;
  text-decoration: none;
}
</style>
