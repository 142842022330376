import { Module, ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { IRootState } from "@/store";
import api from "./apiCalls";

export const state: ISetUpState = {
  datasets: undefined,
  loaded: false,
};

export interface ISetUpState {
  datasets?: IDatasets;
  currentCfg?: ICfg;
  currentClurl?: IClurl;
  loaded: boolean;
}

export interface ICustomUrl {
  url: String;
}

export interface IDatasets {
  dataset_id: string;
  name: string;
  total_accounts?: number;
  purchase_date?: any;
  purchaser: string;
}

export interface IClurl {
  aggregate: boolean;
  display_name: string;
  filter?: boolean;
  name?: any;
  show: boolean;
}

export interface ICfg {
  aggregate: boolean;
  display_name: string;
  filter?: boolean;
  name?: any;
  show: boolean;
}

const actions: ActionTree<ISetUpState, IRootState> = {
  getECGAutocomplete(store: ActionContext<ISetUpState, any>, query: string): Promise<object> {
    return api.getECGAutocomplete(query);
  },

  getVisitorTrackerCode(store: ActionContext<ISetUpState, any>, payload: { modelId: string; teamId: string }): Promise<object> {
    return api.getVisitorTrackerCode(payload.modelId, payload.teamId);
  },

  getExportSettings(store: ActionContext<ISetUpState, any>, payload: { modelId: string; teamId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorHandler: (error: any) => void = (error: any) => {
        console.error("error in getExportSettings");
        reject(error);
      };
      if (!payload.modelId || !payload.teamId) {
        errorHandler({ error: "empty teamId or modelId in getExportSettings" });
        return;
      }
      api.getExportSettings(payload.modelId, payload.teamId).then(successHandler).catch(errorHandler);
    });
  },

  postExportSettings(store: ActionContext<ISetUpState, any>, payload: { modelId: string; teamId: string; settings: any }): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorHandler: (error: any) => void = (error: any) => {
        console.error("error in getExportSettings");
        reject(error);
      };
      if (!payload.modelId || !payload.teamId) {
        errorHandler({ error: "empty teamId or modelId in postExportSettings" });
        return;
      }
      api.postExportSettings(payload.modelId, payload.teamId, payload.settings).then(successHandler).catch(errorHandler);
    });
  },

  getModelProfile(store: ActionContext<ISetUpState, any>, payload: { modelId: string; teamId: string }): Promise<object> {
    return api.getModelProfile(payload.modelId, payload.teamId);
  },

  getDatasets(store: ActionContext<ISetUpState, any>, payload: { teamId: string }): Promise<IDatasets> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getRecentAccounts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        const datasets: IDatasets = data as IDatasets;
        if (datasets) {
          resolve(datasets);
          return;
        }
        console.error("getDatasets error", "response is not IDatasets", data);
        reject(new Error("response is not IDatasets"));
      };

      if (!payload.teamId) {
        errorHandler({ error: "empty parameters for getDatasets API call" });
        return;
      }
      api.getDatasets(payload.teamId).then(successHandler).catch(errorHandler);
    });
  },

  getDataset(store: ActionContext<ISetUpState, any>, payload: { teamId: string; datasetId: string }): Promise<IDatasets> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getDataset error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        const datasets: IDatasets = data as IDatasets;
        if (datasets) {
          resolve(datasets);
          return;
        }
        console.error("getDataset error", "response is not IDatasets", data);
        reject(new Error("response is not IDatasets"));
      };

      if (!payload.teamId || !payload.datasetId) {
        errorHandler({ error: "empty parameters for getDataset API call" });
        return;
      }
      api.getDataset(payload.teamId, payload.datasetId).then(successHandler).catch(errorHandler);
    });
  },

  getCustomFactorGroups(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string }): Promise<ICfg> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getCustomFactorGroups error", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        const cfgs: ICfg = data.hasOwnProperty("custom_factor_groups") ? (data.custom_factor_groups as any) : [];
        if (cfgs) {
          resolve(cfgs);
          return;
        }
        console.error("getCustomFactorGroups error", "response is not ICfg", data);
        reject(new Error("response is not ICfg"));
      };

      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for getCustomFactorGroups API call" });
        return;
      }
      api.getCustomFactorGroups(payload.teamId, payload.modelId).then(successHandler).catch(errorHandler);
    });
  },

  getAccountsInDataset(store: ActionContext<ISetUpState, any>, payload: { teamId: string; datasetId: string; skip: number; limit: number }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getAccountsInDataset error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("patchCustomFactorGroups error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.datasetId) {
        errorHandler({ error: "empty parameters for getAccountsInDataset API call" });
        return;
      }
      api.getAccountsInDataset(payload.teamId, payload.datasetId, payload.skip, payload.limit).then(successHandler).catch(errorHandler);
    });
  },

  queryAccounts(store: ActionContext<ISetUpState, any>, payload: { modelId: string; datasetId: string; saved_by: string; start_date: string; end_date: string; skip: number; limit: number }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("queryAccounts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("queryAccounts error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.modelId || !payload.datasetId) {
        errorHandler({ error: "empty parameters for queryAccounts API call" });
        return;
      }
      api.queryAccounts(payload.modelId, payload.datasetId, payload.saved_by, payload.start_date, payload.end_date, payload.skip, payload.limit).then(successHandler).catch(errorHandler);
    });
  },

  patchCustomFactorGroups(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; name: string; patchData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("patchCustomFactorGroups error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("patchCustomFactorGroups error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId || !payload.name || !payload.patchData) {
        errorHandler({ error: "empty parameters for patchCustomFactorGroups API call" });
        return;
      }
      api.patchCustomFactorGroups(payload.teamId, payload.modelId, payload.name, payload.patchData).then(successHandler).catch(errorHandler);
    });
  },
  /* istanbul ignore next */
  deleteCustomFactorGroups(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; cfgName: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("deleteCustomFactorGroups error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("deleteCustomFactorGroups error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId || !payload.cfgName) {
        errorHandler({ error: "empty parameters for deleteCustomFactorGroups API call" });
        return;
      }
      api.deleteCustomFactorGroups(payload.teamId, payload.modelId, payload.cfgName).then(successHandler).catch(errorHandler);
    });
  },
  /* istanbul ignore next */
  postCustomFactorGroups(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postCustomFactorGroups error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postCustomFactorGroups error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for postCustomFactorGroups API call", payload });
        return;
      }
      if (!payload.modelId) {
        errorHandler({ error: "empty modelId for postCustomFactorGroups API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for postCustomFactorGroups API call", payload });
        return;
      }
      api.postCustomFactorGroups(payload.teamId, payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },

  getCustomLinkedinInfo(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; tld?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getCustomLinkedinInfo error", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getCustomLinkedinInfo error", "response is not object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for getCustomLinkedinInfo API call" });
        return;
      }
      api.getCustomLinkedinInfo(payload.teamId, payload.modelId, payload.tld).then(successHandler).catch(errorHandler);
    });
  },

  patchCustomLinkedinInfo(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; name: string; patchData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("patchCustomLinkedinInfo error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("patchCustomLinkedinInfo error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId || !payload.name || !payload.patchData) {
        errorHandler({ error: "empty parameters for patchCustomLinkedinInfo API call" });
        return;
      }
      api.patchCustomLinkedinInfo(payload.teamId, payload.modelId, payload.name, payload.patchData).then(successHandler).catch(errorHandler);
    });
  },

  downloadDataset(store: ActionContext<ISetUpState, any>, payload: { teamId: string; datasetId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("downloadDataset error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        const accounts: any = data;
        if (accounts) {
          resolve(accounts);
          return;
        }
        console.error("downloadDataset error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.datasetId) {
        errorHandler({ error: "empty parameters for downloadDataset API call" });
        return;
      }
      api.downloadDataset(payload.teamId, payload.datasetId).then(successHandler).catch(errorHandler);
    });
  },
  /* istanbul ignore next */
  setCurrentCfg(store: ActionContext<ISetUpState, any>, data: object): void {
    store.commit("CurrentCfg", data);
  },
  setCurrentLsUrl(store: ActionContext<ISetUpState, any>, data: object): void {
    store.commit("currentClurl", data);
  },
  getPersonaAutocomplete(): Promise<object> {
    return api.getPersonaAutocomplete();
  },
  getPersonas(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; showSytemPersona: boolean }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getPersonas error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getPersonas error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for getPersonas API call" });
        return;
      }
      api.getPersonas(payload.teamId, payload.modelId, payload.showSytemPersona).then(successHandler).catch(errorHandler);
    });
  },
  getPersonaCount(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; personaId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getPersonaCount error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getPersonaCount error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId || !payload.modelId || !payload.personaId) {
        errorHandler({ error: "empty parameters for getPersonaCount API call" });
        return;
      }
      api.getPersonaCount(payload.teamId, payload.modelId, payload.personaId).then(successHandler).catch(errorHandler);
    });
  },
  patchBuyerPersona(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; personaId: string; patchData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("patchBuyerPersona error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("patchBuyerPersona error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId || !payload.personaId || !payload.patchData) {
        errorHandler({ error: "empty parameters for patchBuyerPersona API call" });
        return;
      }
      api.patchBuyerPersona(payload.teamId, payload.modelId, payload.personaId, payload.patchData).then(successHandler).catch(errorHandler);
    });
  },
  postBuyerPersona(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postBuyerPersona error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postBuyerPersona error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for postBuyerPersona API call", payload });
        return;
      }
      if (!payload.modelId) {
        errorHandler({ error: "empty modelId for postBuyerPersona API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for postBuyerPersona API call", payload });
        return;
      }
      api.postBuyerPersona(payload.teamId, payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
  previewBuyerPersona(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("previewBuyerPersona error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("previewBuyerPersona error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.postData) {
        errorHandler({ error: "empty postData for previewBuyerPersona API call", payload });
        return;
      }
      api.previewBuyerPersona(payload.postData, payload.modelId).then(successHandler).catch(errorHandler);
    });
  },
  queryBuyerPersona(store: ActionContext<ISetUpState, any>, payload: { modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("queryBuyerPersona error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("queryBuyerPersona error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.modelId) {
        errorHandler({ error: "empty modelId for queryBuyerPersona API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for queryBuyerPersona API call", payload });
        return;
      }
      api.queryBuyerPersona(payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
  previewBuyerPersonaCount(store: ActionContext<ISetUpState, any>, payload: { postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("previewBuyerPersona error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("previewBuyerPersona error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.postData) {
        errorHandler({ error: "empty postData for previewBuyerPersona API call", payload });
        return;
      }
      api.previewBuyerPersonaCount(payload.postData).then(successHandler).catch(errorHandler);
    });
  },
  deleteBuyerPersona(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; personaId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("deleteBuyerPersona error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("deleteBuyerPersona error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId || !payload.personaId) {
        errorHandler({ error: "empty parameters for deleteBuyerPersona API call" });
        return;
      }
      api.deleteBuyerPersona(payload.teamId, payload.modelId, payload.personaId).then(successHandler).catch(errorHandler);
    });
  },
  downloadBuyerPersona(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; personas?: string[]; contacts?: string[] }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("downloadBuyerPersona error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("downloadBuyerPersona error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId || (!payload.personas && !payload.contacts)) {
        errorHandler({ error: "empty parameters for downloadBuyerPersona API call" });
        return;
      }
      api.downloadBuyerPersona(payload.teamId, payload.modelId, payload.personas, payload.contacts).then(successHandler).catch(errorHandler);
    });
  },

  getSavedContacts(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getSavedContacts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getSavedContacts error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for getSavedContacts API call" });
        return;
      }
      api.getSavedContacts(payload.teamId, payload.modelId).then(successHandler).catch(errorHandler);
    });
  },

  getSavedContactsForAccount(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; companyId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getSavedContactsForAccount error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getSavedContactsForAccount error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId || !payload.modelId || !payload.companyId) {
        errorHandler({ error: "empty parameters for getSavedContactsForAccount API call" });
        return;
      }
      api.getSavedContactsForAccount(payload.teamId, payload.modelId, payload.companyId).then(successHandler).catch(errorHandler);
    });
  },

  getPersonaContacts(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; personaId: string; skip: number; limit: number }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getPersonaContacts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getPersonaContacts error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for getPersonaContacts API call" });
        return;
      }
      api.getPersonaContacts(payload.teamId, payload.modelId, payload.personaId, payload.skip, payload.limit).then(successHandler).catch(errorHandler);
    });
  },

  getOwnedContacts(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; personaId: string; skip: number; limit: number }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getOwnedContacts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getOwnedContacts error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for getOwnedContacts API call" });
        return;
      }
      api.getOwnedContacts(payload.teamId, payload.modelId, payload.personaId, payload.skip, payload.limit).then(successHandler).catch(errorHandler);
    });
  },

  exportContacts(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; integrationType: string; entityType: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("exportContacts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("exportContacts error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId || !payload.integrationType || !payload.entityType) {
        errorHandler({ error: "empty parameters for exportContacts API call" });
        return;
      }
      api.exportContacts(payload.teamId, payload.modelId, payload.integrationType, payload.entityType, payload.postData).then(successHandler).catch(errorHandler);
    });
  },

  getPersonaSettings(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string }): Promise<ICfg> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getPersonaSettings error", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: any) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("exportContacts error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for getPersonaSettings API call" });
        return;
      }
      api.getPersonaSettings(payload.teamId, payload.modelId).then(successHandler).catch(errorHandler);
    });
  },
  postPersonaSettings(store: ActionContext<ISetUpState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postPersonaSettings error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postPersonaSettings error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };

      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for postPersonaSettings API call" });
        return;
      }
      api.postPersonaSettings(payload.teamId, payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
};

const getters: GetterTree<ISetUpState, IRootState> = {
  getCurrentCfg(state: ISetUpState): string | null {
    const { currentCfg }: any = state;
    return currentCfg;
  },
};

const mutations: MutationTree<ISetUpState> = {
  setTargetParamsFinalized(state: ISetUpState, flag: boolean): void {
    state.loaded = flag;
  },
  CurrentCfg(state: ISetUpState, cfg: ICfg): void {
    state.currentCfg = cfg;
  },
};

const namespaced: boolean = true;
export const setUpStore: Module<ISetUpState, IRootState> = {
  actions,
  namespaced,
  mutations,
  state,
  getters,
};
