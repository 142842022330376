import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import subMonths from "date-fns/subMonths";

export const MonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export interface DateRange {
  start: string;
  end: string;
}

/**
 * We will have a finite set of date formats and we can simplfiy the number of helper methods by using a single method
 * This is meant to be used with @formatDateByPreset
 */
export const datePresets = {
  /** 01 Sept 2022 */
  dayNumMonthStringYear: "dd MMM yyyy",
  yearMonthNumDayNum: "yyyy-MM-dd",
  /** Jul 2022 */
  monthStringYear: "MMM yyyy",
  /** 12th Apr 2023 05:30AM */
  dayNumMonthStringYearTime: "do MMM yyyy, hh:mma",
  /** 12th Apr 2023 */
  dayNumStrMonthStrYear: "do MMM yyyy",
  /** 12th Apr */
  dayNumStrMonthStr: "do MMM",
  /** September */
  monthName: "MMMM",
  /** September 2023 */
  monthNameYear: "MMMM yyyy",
  /** 09 */
  monthNum: "MM",
  monthDayYear: "MMM dd, yyyy",
  year: "yyyy",
};

export interface FormatDateByPresetOptions {
  /** The date to format */
  date: Date | string;
  /** The preset to use */
  preset: string;
  /** Return "This week" or "This month" otherwise use date format */
  friendlyRecentDate?: boolean;
}
export function formatDateByPreset({ date, preset, friendlyRecentDate }: FormatDateByPresetOptions): string {
  let newDate = "";
  const dateObj = new Date(date);

  if (date && preset) {
    if (friendlyRecentDate) {
      if (isDateThisMonth(dateObj)) {
        if (isDateThisWeek(dateObj)) return "This week";
        else return "This month";
      }
    }
    newDate = format(dateObj, preset);
  }
  return newDate;
}

export function isDateThisMonth(date: Date): boolean {
  const today = new Date();
  return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth();
}

function isDateThisWeek(date: Date): boolean {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

export const formatDateNotime = (value: string): string => {
  if (value) {
    return format(new Date(value), "MMM d, yyyy");
  }
  return "";
};

export const formatDateToAPI = (value: Date | string): string => {
  if (value) {
    const date = getDateIfString(value);
    if (!date) return "";
    return formatDateByPreset({ date, preset: datePresets.yearMonthNumDayNum });
  }
  return "";
};

export const formatDateToQuery = (value: Date | string): string => {
  if (typeof value === "string" && ["monday", "yesterday", "today"].includes(value)) return value;
  // used toISOString to always get timezone zero UTC offset
  let newValue: string = value instanceof Date ? value.toISOString().substring(0, 10) : value;

  // this replacement is important to avoid js subtracting day by one
  newValue = newValue.replace(/-/g, "/");

  return formatDateByPreset({ date: newValue, preset: datePresets.yearMonthNumDayNum });
};

export const getMonday = (d = new Date()) => {
  const date = new Date(d); // today as default
  const day = date.getDay(), // returns week day from 0 (sunday) to 6
    diff = date.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(date.setDate(diff));
};

export const getYesterday = (d = new Date()) => {
  return new Date(d.setDate(d.getDate() - 1));
};

export const getToday = () => {
  return new Date();
};

export const getDateIfString = (date: Date | string | undefined): Date | string => {
  if (!date) return "";
  if (date instanceof Date) {
    return date;
  } else {
    // this replacement is important to avoid js subtracting day by one
    const newDate = date.replace(/-/g, "/");
    switch (newDate) {
      case "yesterday":
        return getYesterday();
      case "monday":
        return getMonday();
      case "today":
        return getToday();
      default:
        if (newDate) {
          try {
            return new Date(newDate);
          } catch {
            return date;
          }
        }
        return date;
    }
  }
};

export const formatDateFromNow = (d: string): string => {
  const lastUpdatedInDays = differenceInCalendarDays(new Date(), new Date(d));
  let lastUpdatedLabel = formatDistance(new Date(d), new Date(), { addSuffix: true });
  if (lastUpdatedInDays && lastUpdatedInDays <= 30) {
    lastUpdatedLabel = "< 1 month ago";
  } else if (lastUpdatedInDays && lastUpdatedInDays > 30 && lastUpdatedInDays <= 60) {
    lastUpdatedLabel = "< 2 months ago";
  } else if (lastUpdatedInDays && lastUpdatedInDays > 60 && lastUpdatedInDays <= 90) {
    lastUpdatedLabel = "< 3 months ago";
  } else if (lastUpdatedInDays && lastUpdatedInDays > 90 && lastUpdatedInDays <= 180) {
    lastUpdatedLabel = "< 6 months ago";
  } else if (lastUpdatedInDays && lastUpdatedInDays > 180 && lastUpdatedInDays <= 365) {
    lastUpdatedLabel = "< 12 months ago";
  } else if (lastUpdatedInDays && lastUpdatedInDays > 365 && lastUpdatedInDays <= 730) {
    lastUpdatedLabel = "< 2 years ago";
  } else if (lastUpdatedInDays && lastUpdatedInDays > 730) {
    lastUpdatedLabel = "2 or more years ago";
  }
  return lastUpdatedLabel;
};

export const getLastDayOfMonth = (date: Date) => {
  return formatDateByPreset({
    date: lastDayOfMonth(date),
    preset: datePresets.yearMonthNumDayNum,
  });
};

export const subtractMonths = (date: Date, count: number) => {
  return subMonths(date, count);
};
