import { RouteLocation } from "vue-router";

/**
 *  if the model changes we want to keep the user the user's context if they're on one of these or the paths
 * listed in `pagesToPushToName`
 */
const pagesToReload = [
  // engage section search
  "accounts",
  // engage section list of my saved searches
  "mySearches",
  // engage section list of all saved searches
  "allSearches",
  "buyerpersona",
  "segmentaccounts",
  "segmentoverview",
  "purchasedtargetaccounts",
  "firmographicChart",
  "accountList",
  "filteraccounts",
  "filteroverview",
  "recent",
  "saveddatasets",
  "savedcontacts",
  "tagged",
  "modelinsights",
];

const pagesToPushToName = ["savedcontacts", "saveddatasets", "insights", "insights-technologies", "insights-people"];

/**
 * route paths that should be reloaded when the model changes.
 * using the path allows groups of pages to be reloaded instead of having top specify each page
 */
const routePathsToReload = ["/model-setup/", "/setup/", "/settings/", "/team/", "/integrations/"];

/**
 * Determines if the user should:
 * 1. stay on the current page
 * 2. redirect to another page
 * 3. redirect to the default page for the new model
 * @returns {string} the name of the route to redirect to or "reload" to reload the current page
 */
export function routeToStayOn(route: RouteLocation, modelId: string): object | "reload" | undefined {
  const defaultRoute = undefined;

  const routeName = route.name?.toString();
  if (!routeName) return defaultRoute;

  // if we're on the saved search screen and the solution has changed, there's
  // no guarantee that the saved search is still valid for the new solution so we return to the list of My Searches
  if (routeName === "accounts") return { name: "mySearches" }; // take to the parent searches list

  // discover page needs the modelid to be passed in
  if (routeName === "filteraccounts") return { name: routeName, query: { modelId: modelId } };

  if (pagesToPushToName.includes(routeName)) return { name: routeName };

  if (routePathsToReload.some((path) => route.path.includes(path))) return "reload";

  if (pagesToReload.includes(routeName)) return "reload";
  return defaultRoute;
}
