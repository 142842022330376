import { defineStore } from "pinia";
import api from "@/shared/apiCalls";

export const useShared = defineStore("shared", {
  state: () => ({
    loaded: true,
    errors: [],
    quotaExceededErrorShown: false,
    isSidebarExpanded: true,
    showClearbitMsg: false,
    ariaLiveText: "",
  }),
  actions: {
    getCompaniesAutocomplete(query: string) {
      return api.getCompaniesAutocomplete(query);
    },
    // This method is a bit of a hack.
    // So, for accessibility purposes, you can set a role of
    // aria-live to have text-to-speech software to read out the
    // text. This works flawlessly on VoiceOver for macOS.
    // However, when testing NVDA the text was not read at all.
    // Took a lot of time to find out that this is a feature, not
    // a bug (at least that's how they see it).
    // NVDA and other text-to-speech might not read text when it
    // is created (injected in the DOM). They only read it when
    // the content is UPDATED.
    // For things like modals, it would be too cumbersome to be
    // dealing with this whenever we want to read out a notification.
    // So, this method was created.
    // There's a 1x1px div in app.vue that contains the aria-live
    // prop. It's empty by default and will always contain the latest
    // value of this store's ariaLiveText.
    // So whenever a text needs to be read out loud, just call this
    // method with the desired string and be done with it.
    setAriaLiveText(text: string) {
      this.ariaLiveText = text;
    },
  },
});
