import { /*Store, */ Module, ActionContext, ActionTree } from "vuex";
import { IRootState } from "@/store";
import api from "./apiCalls";

export interface ISettingsState {}

const actions: ActionTree<ISettingsState, IRootState> = {
  getAlerts(store: ActionContext<ISettingsState, any>, payload: { teamId: string; userId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getAlerts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getAlerts error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty parameter teamId for getAlerts API call" });
        return;
      }
      if (!payload.userId) {
        errorHandler({ error: "empty parameter userId for getAlerts API call" });
        return;
      }
      api.getAlerts(payload.teamId, payload.userId).then(successHandler).catch(errorHandler);
    });
  },

  modifyAlert(
    store: ActionContext<ISettingsState, any>,
    payload: {
      teamId: string;
      userId: string;
      alert_type: string;
      alert_status: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("modifyAlert error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("modifyAlert error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };

      if (!payload.teamId) {
        errorHandler({ error: "empty parameter teamId for modifyAlert API call" });
        return;
      }
      if (!payload.userId) {
        errorHandler({ error: "empty parameter userId for modifyAlert API call" });
        return;
      }
      if (!payload.alert_type) {
        errorHandler({ error: "empty parameter alert_type for modifyAlert API call" });
        return;
      }
      if (!payload.alert_status) {
        errorHandler({ error: "empty parameter alert_status for modifyAlert API call" });
        return;
      }
      api.modifyAlert(payload.teamId, payload.userId, payload.alert_type, payload.alert_status).then(successHandler).catch(errorHandler);
    });
  },
  modifyEmailAlerts(
    store: ActionContext<ISettingsState, any>,
    payload: {
      teamId: string;
      userId: string;
      alert_status: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("modifyEmailAlerts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("modifyEmailAlerts error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };

      if (!payload.teamId) {
        errorHandler({ error: "empty parameter teamId for modifyEmailAlerts API call" });
        return;
      }
      if (!payload.userId) {
        errorHandler({ error: "empty parameter userId for modifyEmailAlerts API call" });
        return;
      }
      if (!payload.alert_status) {
        errorHandler({ error: "empty parameter alert_status for modifyEmailAlerts API call" });
        return;
      }
      api.modifyEmailAlerts(payload.teamId, payload.userId, payload.alert_status).then(successHandler).catch(errorHandler);
    });
  },
};

const namespaced: boolean = true;
export const settingsStore: Module<ISettingsState, IRootState> = {
  namespaced,
  actions,
};
