import axios from "axios";
import utils from "@/shared/utils";

export default {
  getRecentAccounts(teamId: string, userId: string, skip: number, limit: number): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/teams/" + teamId + "/users/" + userId + "/companies/recent/?skip=" + skip + "&limit=" + limit, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getTags(teamId: string, userId: string, query?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const params: { q?: string } = {};
      if (query) {
        params["q"] = query;
      }
      axios
        .get(utils.MODEL_API + "v2/teams/" + teamId + "/users/" + userId + "/tags/autocomplete/", { params: params })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getAccountsWithTag(accountId: string, ownerId: string, tag_name: string, skip: number, limit: number): Promise<object> {
    return new Promise((resolve, reject) => {
      const baseUrl = utils.MODEL_API + "v2/teams/" + accountId + "/users/" + ownerId + "/tags/" + tag_name + "/companies/";
      const getUrl = skip !== undefined ? baseUrl + "?skip=" + skip + "&limit=" + limit : baseUrl;
      axios
        .get(getUrl, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  downloadAccounts(teamId: string, modelId: string, segmentId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/" + segmentId + "/download/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  deleteSegment(modelId: string, segmentId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "models/" + modelId + "/groups/" + segmentId + "/", {
          delete: true,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
