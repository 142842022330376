/* eslint-disable no-console */

import { register } from "register-service-worker";

const SW_UPDATE_INTERVAL = 5000;

// enabled service worker for all environments except development
if (process?.env?.VUE_APP_MODE && ["production", "preproduction", "stage", "testing1", "testing1_upgrade"].includes(process.env.VUE_APP_MODE)) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // console.error("rsw ready");
    },
    registered(sw: any) {
      // console.error("rsw registered", sw);
      setInterval(() => {
        sw.update()
          .then(() => {
            // console.error("SW update", registration);
          })
          .catch((error: any) => {
            console.error("SW update error", error);
          });
      }, SW_UPDATE_INTERVAL);
    },
    cached() {
      // console.error("cached for offline use.");
    },
    updatefound(sw: any) {
      // console.error("rsw updatefound");
      document.dispatchEvent(new CustomEvent("swUpdateAvailable", { detail: sw }));
      sw.update();
    },
    updated(sw: any) {
      // console.error("rsw updated");
      document.dispatchEvent(new CustomEvent("swUpdated", { detail: sw }));
    },
    offline() {
      // console.error("rsw offline mode");
    },
    error(error: any) {
      console.error("rsw error", error);
    },
  });
}
