import { defineStore } from "pinia";
import { useGetters } from "@/shared/utils/storeHelpers";
import constants from "@/pages/history/shared/constants";
import { SavedAccounts } from "./types";
import { getFilters, getAccounts } from "./apiCalls";
import { formatDateToAPI } from "@/shared/utils/dates";
import { AutoComplete, AutocompleteValue } from "@/types/autocomplete";
import { Exports } from "@/types/exports";
import { SearchSpecSortDirection } from "@/types/accountSearch";
import { getDatasetsAndSavedSearches, getNewDatasetsAndSavedSearches } from "../shared/accountsContactsHelpers";

// const { activeTeamId } = useGetters("team", ["activeTeamId"]);
const { activeModelId } = useGetters("model", ["activeModelId"]);

function createDefaultState() {
  const state = {
    datasetId: "",
    savedBy: "",
    savedOn: {
      start: null as string | null,
      end: null as string | null,
    },
    exportedBy: "",
    exportedOn: {
      start: null as string | null,
      end: null as string | null,
    },
    exportedTo: "",
    excludeAllExports: false,
    limit: constants.PAGE_LIMIT,
    skip: 1,
    sortBy: null as string | null,
    direction: null as SearchSpecSortDirection | null,
  } as SavedAccounts.PageState;
  return state;
}

export const useSavedAccounts = defineStore("saved-accounts", {
  state: () => {
    const pageState = createDefaultState();
    return {
      // Abort controllers for cancelling requests
      getAccountsAbortController: null as AbortController | null,
      datasets: [] as Array<AutocompleteValue>,
      newDatasets: [] as Array<AutocompleteValue>,
      savedSearches: [] as Array<AutocompleteValue>,
      accountsError: null as string | null,
      exportError: null,
      loadingAccounts: true,
      loadingFilters: true,
      teamUsers: [] as Array<AutocompleteValue>,
      usersError: null as string | null,
      filtersError: null,

      /** Used for sending to the API */
      pageState,
      filtersState: {
        showDatasetId: true,
        showSavedBy: false,
        showSavedOn: false,
        showExportedBy: false,
        showExportedOn: false,
        showExportedTo: false,
      },
      accountsData: {
        accounts: [] as Array<SavedAccounts.Account>,
        additional_data: {},
      } as SavedAccounts.AccountsResponse,
      checkedAccounts: {} as { [key: string]: SavedAccounts.CheckedAccount },
    };
  },
  getters: {
    dataset(): AutocompleteValue | undefined {
      return this.datasets.find((dataset: any) => dataset.value === this.pageState.datasetId);
    },
    // Update selected accounts to include props required to export/download the accounts
    selectedAccountsEntity(): Exports.ExportInput[] | undefined {
      const accountsEntity: Exports.ExportInput[] = [];
      Object.entries(this.checkedAccounts).forEach(([key]) => {
        const account: Exports.ExportInput = {
          entity_data: {
            customer_product_id: activeModelId.value,
          },
          entity_config: {},
          entity_id: key,
          entity_name: this.checkedAccounts[key].account_name,
        };
        accountsEntity.push(account);
      });
      return accountsEntity;
    },
    hasFilters(): boolean {
      const { savedBy, savedOn, datasetId, exportedBy, exportedTo, exportedOn } = this.pageState;
      return savedBy !== "" || savedOn?.start !== null || savedOn?.end !== null || datasetId !== "" || exportedBy !== "" || exportedTo !== "" || exportedOn?.start !== null || exportedOn?.end !== null;
    },
  },
  actions: {
    updatePageState(pageState: SavedAccounts.PageState) {
      this.pageState = pageState;
    },
    resetErrors() {
      this.exportError = null;
      this.filtersError = null;
    },
    setExportError(error: any) {
      this.exportError = error;
    },
    async loadAccounts() {
      // There is a timing issue when a request is canceled. The loading spinner is hidden in the finally {} and it has no way of knowing
      // the request was canceled. This var will be set to true in the catch {} and the finally {} will check it before hiding the spinner.
      let canceled = false;

      if (this.getAccountsAbortController) {
        this.getAccountsAbortController.abort(); // Cancel previous request
      }
      this.getAccountsAbortController = new AbortController();
      this.loadingAccounts = true;
      this.accountsError = null;

      // reset accounts object
      this.resetAccountsData();

      const spec: SavedAccounts.AccountSpec = {
        ...(this.pageState.datasetId && { dataset_id: this.pageState.datasetId }),
        ...(this.pageState.savedBy && { saved_by: this.pageState.savedBy }),
        ...(this.pageState.savedOn?.start && { start_date: formatDateToAPI(this.pageState.savedOn.start) }),
        ...(this.pageState.savedOn?.end && { end_date: formatDateToAPI(this.pageState.savedOn.end) }),
        ...(this.pageState.sortBy && { sort_by: this.pageState.sortBy }),
        ...(this.pageState.direction && { desc: this.pageState.direction === "desc" }),
        ...(this.pageState.exportedBy && { exported_by: this.pageState.exportedBy }),
        ...(this.pageState.exportedOn?.start && { export_start_date: formatDateToAPI(this.pageState.exportedOn.start) }),
        ...(this.pageState.exportedOn?.end && { export_end_date: formatDateToAPI(this.pageState.exportedOn.end) }),
        ...(this.pageState.exportedTo && { exported_to: this.pageState.exportedTo }),
        ...(this.pageState.excludeAllExports && { exclude_all_exports: this.pageState.excludeAllExports }),
      };

      try {
        const data = await getAccounts({
          modelId: activeModelId.value,
          spec,
          limit: this.pageState.limit,
          skip: (this.pageState.skip - 1) * this.pageState.limit,
          signal: this.getAccountsAbortController.signal,
        });

        if (data?.accounts?.length) {
          this.accountsData = data;
        }
      } catch (error: any) {
        // if canceled, don't set error, but let the finally {} know it was canceled
        if (error.message === "canceled") {
          canceled = true;
          return;
        }
        this.accountsError = error.message;
      } finally {
        if (!canceled) {
          this.loadingAccounts = false;
        }
        this.getAccountsAbortController = null;
      }
    },
    async loadFilters() {
      try {
        this.loadingFilters = true;
        this.filtersError = null;

        // get list of all possible filters
        const filters = await getFilters({ modelId: activeModelId.value });
        if (filters?.length) {
          this.datasets = getDatasetsAndSavedSearches({ filters });
          this.newDatasets = getNewDatasetsAndSavedSearches({ filters });
          this.teamUsers = filters.find((filter: AutoComplete) => filter.id === "owners")?.autocomplete || [];
        }
      } catch (error: any) {
        this.filtersError = error.message;
      } finally {
        this.loadingFilters = false;
      }
    },
    clearFilterState() {
      const state = createDefaultState();
      this.checkedAccounts = {};
      this.updatePageState(state);
      this.filtersState = {
        showDatasetId: false,
        showSavedBy: false,
        showSavedOn: false,
        showExportedBy: false,
        showExportedOn: false,
        showExportedTo: false,
      };
    },
    resetFilters() {
      this.datasets = [];
      this.teamUsers = [];
    },
    resetAccountsData() {
      this.loadingAccounts = true;

      this.accountsData = {
        accounts: [],
        additional_data: {},
      };
    },
    /** Dummy action that can be subscribed to
     * Used to trigger a re-render when the page state changes
     * Only used when changing solutions from the NavBar.vue component
     */
    resetState() {},
  },
});
