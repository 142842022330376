<template>
  <div id="no-sidebar-layout">
    <helper-component />
    <div class="container-fluid p-0 w-0 main">
      <div class="row p-0 m-0 no-sidebar">
        <slot />
      </div>
    </div>
    <footer-bar />
  </div>
</template>

<script lang="ts">
import HelperComponent from "@/shared/HelperComponent.vue";
import FooterBar from "@/shared/FooterBar.vue";

export default {
  name: "no-sidebar-layout",
  components: {
    HelperComponent,
    FooterBar,
  },
};
</script>

<style lang="scss" scoped>
.no-sidebar {
  background: #ffffff;
  width: 100%;
}
</style>
