import utils from "@/shared/utils";
const onlyLatestCompaniesAutocomplete = utils.getAndCancelPrevious();

export default {
  getCompaniesAutocomplete(query: string): Promise<object> {
    return new Promise((resolve, reject) => {
      onlyLatestCompaniesAutocomplete(utils.MODEL_API + "v2/ecg/companies/autocomplete/?q=" + query, {
        headers: {
          "content-type": "application/json",
        },
      })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
