import { defineStore } from "pinia";
import { getContacts, getFilters } from "./apiCalls";
import { PersonaContacts } from "@/types/personaContacts";
import { SavedContacts } from "./types";
import constants from "@/pages/history/shared/constants";
import { useGetters } from "@/shared/utils/storeHelpers";
import { AutoComplete, AutocompleteValue } from "@/types/autocomplete";
import { formatDateToAPI } from "@/shared/utils/dates";
import { SearchSpecSortDirection } from "@/types/accountSearch";
import { getDatasetsAndSavedSearches, getNewContactsPersonas, getNewDatasetsAndSavedSearches } from "../shared/accountsContactsHelpers";

const { activeModelId } = useGetters("model", ["activeModelId"]);

function createDefaultState() {
  const state = {
    datasetId: "",
    personaId: "",
    limit: constants.PAGE_LIMIT,
    savedBy: "",
    savedOn: {
      start: null as string | null,
      end: null as string | null,
    },
    exportedBy: "",
    exportedOn: {
      start: null as string | null,
      end: null as string | null,
    },
    exportedTo: "",
    excludeAllExports: false,
    sortBy: null as string | null,
    direction: null as SearchSpecSortDirection | null,
    skip: 1,
  } as SavedContacts.PageState;
  return state;
}

export const useSavedContacts = defineStore("saved-contacts", {
  state: () => {
    const pageState = createDefaultState();
    return {
      // Abort controllers for cancelling requests
      getContactsAbortController: null as AbortController | null,
      contacts: {} as PersonaContacts.APIResponse,
      personas: [] as Array<AutocompleteValue> | undefined,
      loadingContacts: true, // default to true so we can show the loading spinner on page load
      loadingUsers: false,
      loadingFilters: false,
      contactsError: null,
      filtersError: null,
      exportError: null,
      usersError: null as string | null,
      teamUsers: [] as Array<AutocompleteValue>,
      datasets: [] as Array<AutocompleteValue>,
      newDatasets: [] as Array<AutocompleteValue>,
      newContactsDatasets: [] as Array<AutocompleteValue>,
      datasetsError: null as string | null,
      checkedContacts: {} as { [key: string]: SavedContacts.CheckedContact },
      /** Used for sending to the API */
      pageState,
      filtersState: {
        showPersonaId: true,
        showDatasetId: false,
        showSavedBy: false,
        showSavedOn: false,
        showExportedBy: false,
        showExportedOn: false,
        showExportedTo: false,
      },
    };
  },

  getters: {
    hasFilters(): boolean {
      const { savedBy, savedOn, datasetId, personaId, exportedBy, exportedTo, exportedOn } = this.pageState;
      return savedBy !== "" || savedOn?.start !== null || savedOn?.end !== null || datasetId !== "" || personaId !== "" || exportedBy !== "" || exportedTo !== "" || exportedOn?.start !== null || exportedOn?.end !== null;
    },
  },

  actions: {
    /** Dummy action that can be subscribed to
     * Used to trigger a re-render when the page state changes
     * Only used when changing solutions from the NavBar.vue component
     */
    resetState() {},
    setContacts(contacts: any) {
      this.contacts = contacts;
    },
    updatePageState(pageState: SavedContacts.PageState) {
      this.pageState = pageState;
    },

    setPersonas(personas: any) {
      this.personas = personas;
    },
    setExportError(error: any) {
      this.exportError = error;
    },

    async loadContacts(state: SavedContacts.PageState) {
      if (!activeModelId) {
        console.error("modelId or personaId is not defined");
        return;
      }

      // There is a timing issue when a request is canceled. The loading spinner is hidden in the finally {} and it has no way of knowing
      // the request was canceled. This var will be set to true in the catch {} and the finally {} will check it before hiding the spinner.
      let canceled = false;

      if (this.getContactsAbortController) {
        this.getContactsAbortController.abort(); // Cancel previous request
      }
      this.getContactsAbortController = new AbortController();

      try {
        this.setContacts([]); // reset contacts
        this.contactsError = null; // reset error

        this.loadingContacts = true;

        const spec: SavedContacts.ContactSpec = {
          ...(state.datasetId && { dataset_id: state.datasetId }),
          ...(state.savedBy && { saved_by: state.savedBy }),
          ...(state.personaId && { buyer_persona_id: state.personaId }),
          ...(state.savedOn?.start && { start_date: formatDateToAPI(state.savedOn.start) }),
          ...(state.savedOn?.end && { end_date: formatDateToAPI(state.savedOn.end) }),
          ...(this.pageState.sortBy && { sort_by: this.pageState.sortBy }),
          ...(this.pageState.direction && { desc: this.pageState.direction === "desc" }),
          ...(this.pageState.exportedBy && { exported_by: this.pageState.exportedBy }),
          ...(this.pageState.exportedOn?.start && { export_start_date: formatDateToAPI(this.pageState.exportedOn.start) }),
          ...(this.pageState.exportedOn?.end && { export_end_date: formatDateToAPI(this.pageState.exportedOn.end) }),
          ...(this.pageState.exportedTo && { exported_to: this.pageState.exportedTo }),
          ...(this.pageState.excludeAllExports && { exclude_all_exports: this.pageState.excludeAllExports }),
        };
        const contacts = await getContacts({
          limit: state.limit,
          skip: (state.skip - 1) * state.limit,
          modelId: activeModelId.value,
          spec,
          signal: this.getContactsAbortController.signal,
        });
        this.setContacts(contacts);
      } catch (error: any) {
        // if canceled, don't set error, but let the finally {} know it was canceled
        if (error.message === "canceled") {
          canceled = true;
          return;
        }
        this.contactsError = error.message;
      } finally {
        if (!canceled) {
          this.loadingContacts = false;
        }
      }
    },

    async loadFilters() {
      try {
        this.loadingFilters = true;
        this.filtersError = null;
        const filters = await getFilters({ modelId: activeModelId.value });

        this.datasets = getDatasetsAndSavedSearches({ filters });
        this.newDatasets = getNewDatasetsAndSavedSearches({ filters });
        this.newContactsDatasets = getNewContactsPersonas({ filters });

        this.personas = filters.find((filter: AutoComplete) => filter.id === "personas")?.autocomplete;
        this.teamUsers = filters.find((filter: AutoComplete) => filter.id === "owners")?.autocomplete || [];
      } catch (error: any) {
        this.filtersError = error.message;
      } finally {
        this.loadingFilters = false;
      }
    },

    clearFilters() {
      const state = createDefaultState();
      this.checkedContacts = {};
      this.updatePageState(state);
      this.filtersState = {
        showPersonaId: false,
        showDatasetId: false,
        showSavedBy: false,
        showSavedOn: false,
        showExportedBy: false,
        showExportedOn: false,
        showExportedTo: false,
      };
    },
  },
});
