<template>
  <div id="sidebar-layout">
    <helper-component />
    <div class="sidebar">
      <component :is="sideBar" />
    </div>

    <div class="maincontent" v-bind:class="[isSidebarExpanded ? 'wideSideBar' : 'narrowSideBar']" :style="styleMainContent">
      <div class="container-fluid main" :style="styleMain">
        <slot />
      </div>
    </div>
    <footer-bar />
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent } from "vue";
import HelperComponent from "@/shared/HelperComponent.vue";
import FooterBar from "@/shared/FooterBar.vue";
import { useShared } from "@/stores/shared/store";
import { mapState } from "vuex";
import { mapStores } from "pinia";

const alertBarsHeight = 34;

const SideBar = defineAsyncComponent(() => import("@/shared/SideBar.vue"));

export default {
  name: "sidebar-layout",
  components: {
    HelperComponent,
    FooterBar,
  },
  data(): {
    isMounted: boolean;
    styleMain: any;
    styleMainContent: any;
    sideBar: any;
  } {
    return {
      isMounted: false,
      styleMainContent: {
        minHeight: "calc(100vh - 30px - 116px)",
      },
      styleMain: {
        minHeight: "calc(100vh - 30px - 2rem - 116px)",
      },
      sideBar: SideBar,
    };
  },
  methods: {
    getHeaderHeight() {
      const showRefreshbar = this.$store.state.needRefreshBar;
      let header = document.getElementById("layout-header-div");
      let ai_header = document.getElementById("ai-header");
      let headerTopPx = 0;
      if (showRefreshbar) headerTopPx += alertBarsHeight;
      if (this.showUnsupportedBrowserBar) headerTopPx += alertBarsHeight;
      if (this.showCSUserBar) headerTopPx += alertBarsHeight;
      if (ai_header) {
        try {
          if (headerTopPx) {
            ai_header.style.top = `${headerTopPx}px`;
          }
        } catch (err) {
          console.error("Error setting style for ai header div", err);
        }
        return;
      } else if (header) {
        try {
          if (headerTopPx) {
            header.style.top = `${headerTopPx}px`;
          }
        } catch (err) {
          console.error("Error setting style for header div", err);
        }
        return header.clientHeight;
      } else {
        return 116;
      }
    },
    setStyleMain() {
      const showRefreshbar = this.$store.state.needRefreshBar;
      let headerTopPx = 0;
      if (showRefreshbar) headerTopPx += alertBarsHeight;
      if (this.showUnsupportedBrowserBar) headerTopPx += alertBarsHeight;
      if (this.showCSUserBar) headerTopPx += alertBarsHeight;
      let headerHeight = this.getHeaderHeight();
      if (showRefreshbar) {
        headerHeight = headerHeight ? headerHeight + alertBarsHeight : alertBarsHeight;
      }
      const marginTop = (headerHeight ? headerHeight + 24 + headerTopPx : 116 + headerTopPx) + "px";
      const minHeight = "calc(100vh - " + (headerHeight ? headerHeight + 24 + headerTopPx : 116 + headerTopPx) + "px)";

      if (this.styleMain.minHeight !== minHeight) {
        this.styleMain.minHeight = minHeight;
      }

      if (this.styleMain.marginTop !== marginTop && !this.$route.path?.includes("/insights")) {
        this.styleMain.marginTop = marginTop;
      } else if (this.$route.path?.includes("/insights")) {
        // no spacing for insights views
        this.styleMain.marginTop = headerTopPx + "px";
      }
    },
    setStyleMainContent() {
      const headerHeight = this.getHeaderHeight();
      const minHeight = "calc(100vh - 30px - 2rem - " + (headerHeight ? headerHeight + 24 : 116) + "px)";
      if (this.styleMainContent.minHeight !== minHeight) {
        this.styleMainContent.minHeight = minHeight;
      }
    },
    setStyles() {
      this.setStyleMain();
      this.$nextTick(() => {
        this.setStyleMainContent();
      });
    },
  },
  computed: {
    ...mapStores(useShared),
    ...mapState("team", {
      activeTeam: "activeTeam",
    }),
    isSidebarExpanded() {
      return this.sharedStore.isSidebarExpanded;
    },
    showUnsupportedBrowserBar() {
      return !!(
        this.userAgent &&
        (this.userAgent.indexOf("Trident") > -1 ||
          this.userAgent.indexOf("MSIE") > -1 ||
          this.userAgent.indexOf("Edge/18.") > -1 ||
          this.userAgent.indexOf("Edge/17.") > -1 ||
          this.userAgent.indexOf("Edge/16.") > -1 ||
          this.userAgent.indexOf("Edge/15.") > -1)
      );
    },
    showCSUserBar() {
      return this.activeTeam?.role === "enlyft_cs" && !this.$route.path?.includes("/teamSelection");
    },
  },
  mounted() {
    this.setStyles();
  },
  updated() {
    this.setStyles();
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      async handler() {
        await this.$nextTick();
        setTimeout(() => {
          this.setStyles();
        }, 100);
      },
    },
  },
};
</script>
<style>
@media (max-width: 1290px) {
  #sidebar-layout > div.maincontent.wideSideBar > div {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .layout-header-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  #ai-header-container {
    padding-left: 39px !important;
    padding-right: 39px !important;
  }
}
.app-div div.layout-header {
  /* I don't love this, but the header is getting overlapped by the checkbox label component which used z-index: 100
  At some point I would like to replace the header component @kenyon Oct 2022
  */
  z-index: 101;
}
</style>
<style lang="scss" scoped>
#sidebar-layout {
  position: relative;
  min-height: calc(100vh - 25px);

  .sidebar {
    position: fixed;
    top: 100;
    left: 0;
    z-index: 102;
    background: #e4e5eb;
    display: flex;
    color: #202629;
    box-shadow: inset -0.5px 0px 0px rgba(159, 162, 174, 0.6);
  }
  .maincontent {
    background: #ffffff;
    min-height: calc(100vh - 30px - 116px);
    display: flex;
    margin-top: 0;
    transition: ease 150ms all;
  }
  .main {
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 30px - 2rem - 116px);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    max-width: var(--container-max-width);
    padding: 0px 0px 60px 0px !important;
  }
}
.wideSideBar {
  margin-left: var(--sidebar-expanded);
}
.narrowSideBar {
  margin-left: var(--sidebar-narrow);
}
</style>
