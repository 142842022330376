import axios from "axios";
import utils from "@/shared/utils";

const onlyLatestMaterializeCredits = utils.postAndCancelPrevious();

export default {
  getCompanyQuery(query: object, modelId: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/ecg/companies/query/customer_products/" + modelId + "/", { spec: query })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getMaterializeCredits(teamId: string, modelId: string, spec: object): Promise<object> {
    return new Promise((resolve, reject) => {
      onlyLatestMaterializeCredits(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/" + "materialize_estimate" + "/", {
        spec: spec,
      })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  createDataset(teamId: string, modelId: string, spec: object, name: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/datasets/" + teamId + "/customer_products/" + modelId + "/", {
          spec: spec,
          name: name,
          dataset_type: "self_serve",
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  saveDataset(teamId: string, modelId: string, spec: object, name: string, count: number, owner_email?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      let patchData: any = {
        spec: spec,
        name: name,
        dataset_type: "saved_search",
        count_at_creation: count,
      };
      if (owner_email) {
        patchData = { ...patchData, owner_email: owner_email };
      }

      axios
        .post(utils.MODEL_API + "v2/datasets/" + teamId + "/customer_products/" + modelId + "/", patchData)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  editDataset(name: string, description: string, spec: object, teamId: string, datasetId: string, count: number, owner_email?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      let patchData: any = {
        operation: "edit",
        name: name,
        description: description,
        spec: spec,
        count_at_creation: count,
      };
      if (owner_email) {
        patchData = { ...patchData, owner_email: owner_email };
      }
      axios
        .patch(utils.MODEL_API + "v2/datasets/" + teamId + "/" + datasetId + "/", patchData)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  materializeDataset(teamId: string, datasetId: string, limit?: number): Promise<object> {
    return new Promise((resolve, reject) => {
      const patchData: any = {
        operation: "materialize",
        consumption_mode: "bulk",
      };
      if (limit && limit > 0) {
        patchData["limit"] = limit;
      }
      axios
        .patch(utils.MODEL_API + "v2/datasets/" + teamId + "/" + datasetId + "/", patchData)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getRecommendations(product_slug?: [], category_slug?: [], industry?: []): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const params: { product_slug?: string; category_slug?: string; industry_name?: string } = {};
      if (product_slug && product_slug.length > 0 && !industry) {
        params["product_slug"] = product_slug.join(",");
      }
      if (category_slug && category_slug.length > 0 && !industry) {
        params["category_slug"] = category_slug.join(",");
      }
      if (industry) {
        params["industry_name"] = industry.join(",");
      }
      axios
        .get(utils.MODEL_API + "v2/ecg/recommendations/", { params: params })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getTechnologiesRecommendations(query: string, search_in?: string): Promise<object> {
    const param = search_in ? query + "&search_in=" + search_in : query;
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/ecg/recommendations/technologies/?" + param, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  uploadCustomerAccounts(teamId: string, modelId: string, spec: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/datasets/" + teamId + "/" + modelId + "/upload/", spec)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  suppressAccounts(teamId: string, modelId: string, spec: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/datasets/" + teamId + "/" + modelId + "/suppress/upload/", spec)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getUploadEstimate(teamId: string, modelId: string, file: object, fileType: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/datasets/" + teamId + "/" + modelId + "/upload_estimate/" + fileType + "/", file)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getCustomerUploadedDatasets(teamId: string, modelId: string, datasetTypes: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/datasets/teams/" + teamId + "/customer_products/" + modelId + "/?dataset_types=" + datasetTypes)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  deleteSuppressedDataset(teamId: string, datasetId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "v2/datasets/" + teamId + "/" + datasetId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
