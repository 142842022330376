import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-637456c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "empty-layout" }
const _hoisted_2 = { class: "container-fluid p-0 w-0 main" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex justify-content-center m-0 loading-div"
}
const _hoisted_4 = { class: "ml-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_circular_progress = _resolveComponent("c-circular-progress")!
  const _component_footer_bar = _resolveComponent("footer-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($data.isLoading && _ctx.$route.path == '/' && !$data.appNeedsLogin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_c_circular_progress, {
                color: "light",
                size: "large"
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_footer_bar, { layout: "empty" })
  ]))
}