import axios from "axios";
import utils from "@/shared/utils";

export default {
  getPaymentDetails(teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/tenants/" + teamId + "/payment/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getPaymentLink(teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/invoice/payment_link/" + teamId + "/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  postPayment(teamId: string, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/tenants/" + teamId + "/subscription/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
