import format from "date-fns/format";

export default {
  formatDate: (value: any) => {
    if (value) {
      return format(new Date(value), "MM/dd/yyyy HH:mm");
    }
  },

  formatNumber: (value: any) => {
    if (value && typeof value === "number") {
      return Number(value).toLocaleString();
    } else {
      return value;
    }
  },
  formatUndefinedNumberToPrecision: (value: any) => {
    if (value === "N/A" || value === "NA") {
      return "N/A";
    }
    if (value === undefined || isNaN(value)) {
      return "-";
    } else {
      return value.toFixed(2);
    }
  },
  formatDateNotime: (value: any) => {
    if (value) {
      return format(new Date(value), "MMM d, yyyy");
    }
  },
  formatDateFullNotime: (value: any) => {
    if (value) {
      return format(new Date(value), "MMMM d, yyyy");
    }
  },
  formatDateLocaleString: (value: any) => {
    if (value) {
      return new Date(value).toLocaleDateString();
    }
  },

  formatWithEllipsis: (value: any, size: any) => {
    if (!value) return "";
    if (size && typeof size != "number") return "";
    value = value.toString();
    if (value.length <= size) {
      return value;
    }
    return value.substr(0, size) + "...";
  },
};
