import { Module, ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { IRootState } from "@/store";
import { captureException } from "@sentry/vue";

export const state: ISharedState = {
  errors: [],
  quotaExceededErrorShown: true,
};

export interface ISharedState {
  errors: any[];
  quotaExceededErrorShown: boolean;
}

const actions: ActionTree<ISharedState, IRootState> = {
  error(store: ActionContext<ISharedState, any>, error: any) {
    let obj: any = { date: new Date() };
    const exception: string = error.exception ? error.exception : "Network or Access Denied error";
    if (typeof error === "object") {
      switch (error.type) {
        case "network":
        case "access_denied":
          obj = { ...obj, ...error };
          console.error(error);
          captureException(new Error(exception));
          break;
        default:
          obj = { ...obj, ...error };
          break;
      }
    } else if (typeof error === "string") {
      obj.type = "generic";
      obj.message = error;
    } else {
      console.error("unknown error type", error, typeof error);
    }
    store.commit("AddError", obj);
  },
  setQuotaExceededErrorShown(store: ActionContext<ISharedState, any>, flag: boolean): void {
    store.commit("QuotaExceededErrorShown", flag);
  },
};

const mutations: MutationTree<ISharedState> = {
  AddError(state: ISharedState, error: object): void {
    state.errors.push(error);
  },
  QuotaExceededErrorShown(state: ISharedState, flag: boolean): void {
    state.quotaExceededErrorShown = flag;
  },
};

const getters: GetterTree<ISharedState, IRootState> = {
  QuotaExceededErrorShown(state: ISharedState): boolean {
    const { quotaExceededErrorShown } = state;
    return quotaExceededErrorShown;
  },
};

const namespaced: boolean = true;

export const sharedStore: Module<ISharedState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
