/* istanbul ignore file */
import axios from "axios";
import utils from "@/shared/utils";

export default {
  getCRMIntegrations(teamId: string, integrationType?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (teamId !== null) {
        let url = utils.MODEL_API + "v2/teams/" + teamId + "/integrations/";
        if (integrationType !== undefined) {
          url = url + "?integration_type=" + integrationType;
        }
        axios.get(url).then(utils.genericSuccessHandler(resolve, reject)).catch(utils.genericErrorHandler(resolve, reject));
      } else {
        reject(new Error("teamId cant be null"));
      }
    });
  },
  getIntegrationToken(teamId: string, integrationType: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (teamId !== null) {
        const url = utils.MODEL_API + "v2/teams/" + teamId + "/integrations/crm/" + integrationType;
        axios.get(url).then(utils.genericSuccessHandler(resolve, reject)).catch(utils.genericErrorHandler(resolve, reject));
      } else {
        reject(new Error("teamId cant be null"));
      }
    });
  },
  deleteIntegrationToken(teamId: string, integrationType: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (teamId !== null) {
        axios
          .delete(utils.MODEL_API + "v2/teams/" + teamId + "/integrations/crm/" + integrationType)
          .then(utils.genericSuccessHandler(resolve, reject))
          .catch(utils.genericErrorHandler(resolve, reject));
      } else {
        reject(new Error("missing teamId"));
      }
    });
  },
  fetchIntegrationAccessToken(teamId: string, integrationType: string, code: string, version?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (teamId !== null && code !== undefined) {
        const postData: {
          code: string;
          version?: string;
          integration_type: string;
        } = {
          code: code,
          version: version,
          integration_type: integrationType,
        };
        axios
          .post(utils.MODEL_API + "v2/teams/" + teamId + "/integrations/crm/", postData)
          .then(utils.genericSuccessHandler(resolve, reject))
          .catch(utils.genericErrorHandler(resolve, reject));
      } else {
        reject(new Error("missing teamId or code or version"));
      }
    });
  },
  fetchMarketoAccessToken(teamId: string, instance_url?: string, client_id?: string, client_secret?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (teamId !== null) {
        const postData: {
          integration_type: string;
          instance_url?: string;
          client_id?: string;
          client_secret?: string;
        } = {
          integration_type: "marketo",
          instance_url: instance_url,
          client_id: client_id,
          client_secret: client_secret,
        };
        axios
          .post(utils.MODEL_API + "v2/teams/" + teamId + "/integrations/crm/", postData)
          .then(utils.genericSuccessHandler(resolve, reject))
          .catch(utils.genericErrorHandler(resolve, reject));
      } else {
        reject(new Error("missing teamId"));
      }
    });
  },
  getIntegrationSetup(teamId?: string, integrationType?: string, requestStep?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/teams/" + teamId + "/integrations/setup/", {
          params: {
            integration_type: integrationType,
            request_step: requestStep,
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  putSetupIntegration(teamId?: string, payload?: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .put(utils.MODEL_API + "v2/teams/" + teamId + "/integrations/setup/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getIntegrationFieldMapping(teamId: string, integrationType: string, entityType: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (teamId !== null) {
        let url = utils.MODEL_API + "v2/teams/" + teamId + "/integrations/mapping/crm/" + integrationType;
        url = url + "?entity_type=" + entityType;
        axios.get(url).then(utils.genericSuccessHandler(resolve, reject)).catch(utils.genericErrorHandler(resolve, reject));
      } else {
        reject(new Error("teamId cannot be null"));
      }
    });
  },
};
