import axios from "axios";
import utils from "@/shared/utils";

export default {
  getSegmentAutocomplete(modelId: string, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/autocomplete/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  saveSegment(modelId: string, teamId: string, spec: object): Promise<object> {
    return new Promise((resolve, reject) => {
      const post_data: any = { ...spec };
      if (post_data && post_data.spec && post_data.spec.countryState) {
        delete post_data.spec.countryState;
      }
      axios
        .post(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/", post_data)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  patchSegment(modelId: string, teamId: string, segmentId: string, patchData: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/" + segmentId + "/", patchData)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  deleteSegment(modelId: string, teamId: string, segmentId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/" + segmentId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getSegment(modelId: string, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
