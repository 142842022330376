// https://github.com/mixpanel/mixpanel-js
// Usage info

import { isBrowserExtension } from "@/chrome_plugin/utils/environment";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function sendMixPanelEvent(eventName: string, eventData = {}) {
  return;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function mixPanelTrack(eventName: string, eventData = {}) {
  // mixpanelRef.track(eventName, eventData);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function setMixPanelUserProperties(user: any, currentTeam: any, currentOffering: any, userTeams: any) {
  return "";
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function sendMixPanelPageEvent(eventName: string, eventData?: any) {
  return;
}

export function getMixpanelRef() {
  if (!isBrowserExtension && (window as any)?.mixpanel) {
    return (window as any).mixpanel.app;
  }
  return {};
}
