import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { IRootState } from "@/store";
import api from "./apiCalls";
import isEqual from "lodash.isequal";

const getDefaultTargetState = () => {
  return {
    loaded: true,
    recommendation: undefined,
    targetSpec: undefined,
    newAccountsQty: undefined,
    viewedAccountsQty: undefined,
    selectedFilterData: undefined,
    allAccountList: undefined,
    filterQuery: undefined,
    viewedAccountList: undefined,
    newAccountList: undefined,
    tabChanged: undefined,
    payload: {
      type: "",
    },
  };
};

export const state: ITargetAccountsState = getDefaultTargetState();

export interface IDatasets {
  dataset_id: string;
  name: string;
  total_accounts?: number;
  purchase_date?: any;
  purchaser: string;
}

export interface ITargetAccountsState {
  loaded: boolean;
  recommendation?: [];
  targetSpec?: {};
  newAccountsQty?: number;
  viewedAccountsQty?: number;
  selectedFilterData?: {};
  allAccountList?: [];
  viewedAccountList?: [];
  newAccountList?: [];
  filterQuery?: string;
  tabChanged?: string;
  payload: {
    type: string;
  };
}

const actions: ActionTree<ITargetAccountsState, IRootState> = {
  resetTagetAccountsState({ commit }) {
    commit("resetState");
  },
  getCompanyQuery(store: ActionContext<ITargetAccountsState, any>, payload: { query: object; modelId: any }): Promise<object> {
    return api.getCompanyQuery(payload.query, payload.modelId);
  },
  getCustomerUploadedDatasets(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      modelId: string;
      datasetTypes: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: any) => {
        if (error && error.message !== "Only one request allowed at a time.") {
          console.error("getCustomerUploadedDatasets error", error);
        }
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getCustomerUploadedDatasets error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId || !payload.modelId) {
        errorHandler({ error: "empty parameters for getCustomerUploadedDatasets" });
        return;
      }
      api.getCustomerUploadedDatasets(payload.teamId, payload.modelId, payload.datasetTypes).then(successHandler).catch(errorHandler);
    });
  },
  deleteSuppressedDataset(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      datasetId: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: any) => {
        if (error && error.message !== "Only one request allowed at a time.") {
          console.error("deleteSuppressedDataset error", error);
        }
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("deleteSuppressedDataset error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      if (!payload.teamId && !payload.datasetId) {
        errorHandler({ error: "empty parameters for deleteSuppressedDataset" });
        return;
      }
      api.deleteSuppressedDataset(payload.teamId, payload.datasetId).then(successHandler).catch(errorHandler);
    });
  },
  uploadCustomerAccounts(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      modelId: string;
      spec: object;
    },
  ): Promise<object> {
    return api.uploadCustomerAccounts(payload.teamId, payload.modelId, payload.spec);
  },
  suppressAccounts(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      modelId: string;
      spec: object;
    },
  ): Promise<object> {
    return api.suppressAccounts(payload.teamId, payload.modelId, payload.spec);
  },
  getUploadEstimate(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      modelId: string;
      file: object;
      fileType: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getUploadEstimate error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getUploadEstimate error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };
      if (!payload.teamId || !payload.modelId || !payload.file || !payload.fileType) {
        errorHandler({ error: "empty parameters for getUploadEstimate" });
        return;
      }
      api.getUploadEstimate(payload.teamId, payload.modelId, payload.file, payload.fileType).then(successHandler).catch(errorHandler);
    });
  },
  getMaterializeCredits(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      modelId: string;
      spec: object;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: any) => {
        if (error && error.message !== "Only one request allowed at a time.") {
          console.error("getMaterializeCredits error", error);
        }
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getMaterializeCredits error", "response is not an object", data);
        reject(new Error("response is not an object"));
      };
      api.getMaterializeCredits(payload.teamId, payload.modelId, payload.spec).then(successHandler).catch(errorHandler);
    });
  },
  getRecommendation(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      product_slug?: [];
      category_slug?: [];
      industry?: [];
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("recommendations error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("recommendations error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };
      if (!payload.product_slug && !payload.category_slug && !payload.industry) {
        errorHandler({ error: "empty parameters for recommendations" });
        return;
      }
      api.getRecommendations(payload.product_slug, payload.category_slug, payload.industry).then(successHandler).catch(errorHandler);
    });
  },
  getTechnologiesRecommendations(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      query: string;
      search_in?: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        if (data) {
          resolve(data);
        }
      };
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("Fetching technology recommendations failed", error);
        reject(error);
      };
      api.getTechnologiesRecommendations(payload.query, payload.search_in).then(successHandler).catch(errorHandler);
    });
  },
  saveDataset(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      modelId: string;
      spec: object;
      name: string;
      count_at_creation: number;
      owner_email?: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("saveDataset error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("saveDataset error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };

      if (!payload.teamId) {
        errorHandler({ error: "empty parameters teamId for saveDataset API call" });
        return;
      }
      if (!payload.modelId) {
        errorHandler({ error: "empty parameters modelId for saveDataset API call" });
        return;
      }
      if (!payload.spec) {
        errorHandler({ error: "empty parameters spec for saveDataset API call" });
        return;
      }
      let name = "";
      if (payload.name) {
        name = payload.name;
      } else {
        const d = new Date();
        name = "Dataset " + d;
      }
      api.saveDataset(payload.teamId, payload.modelId, payload.spec, name, payload.count_at_creation, payload.owner_email).then(successHandler).catch(errorHandler);
    });
  },
  createDataset(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      modelId: string;
      spec: object;
      name: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("createDataset error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("createDataset error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };

      if (!payload.teamId) {
        errorHandler({ error: "empty parameters teamId for createDataset API call" });
        return;
      }
      if (!payload.modelId) {
        errorHandler({ error: "empty parameters modelId for createDataset API call" });
        return;
      }
      if (!payload.spec) {
        errorHandler({ error: "empty parameters spec for createDataset API call" });
        return;
      }
      let name = "";
      if (payload.name) {
        name = payload.name;
      } else {
        const d = new Date();
        name = "Dataset " + d;
      }
      api.createDataset(payload.teamId, payload.modelId, payload.spec, name).then(successHandler).catch(errorHandler);
    });
  },
  editDataset(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      name: string;
      description: string;
      spec: object;
      teamId: string;
      datasetId: string;
      count_at_creation: number;
      owner_email?: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("editDataset error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("editDataset error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };

      if (!payload.teamId) {
        errorHandler({ error: "empty parameters teamId for editDataset API call" });
        return;
      }
      if (!payload.datasetId) {
        errorHandler({ error: "empty parameters datasetId for editDataset API call" });
        return;
      }
      api.editDataset(payload.name, payload.description, payload.spec, payload.teamId, payload.datasetId, payload.count_at_creation, payload.owner_email).then(successHandler).catch(errorHandler);
    });
  },

  materializeDataset(
    store: ActionContext<ITargetAccountsState, any>,
    payload: {
      teamId: string;
      datasetId: string;
      limit?: number;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("materializeDataset error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("materializeDataset error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };

      if (!payload.teamId) {
        errorHandler({ error: "empty parameters teamId for materializeDataset API call" });
        return;
      }
      if (!payload.datasetId) {
        errorHandler({ error: "empty parameters datasetId for materializeDataset API call" });
        return;
      }
      api.materializeDataset(payload.teamId, payload.datasetId, payload.limit).then(successHandler).catch(errorHandler);
    });
  },
  setTargetSpec(store: ActionContext<ITargetAccountsState, any>, data: object): void {
    store.commit("TargetSpec", data);
  },
  setTabChanged(store: ActionContext<ITargetAccountsState, any>, data: string): void {
    store.commit("TabChanged", data);
  },
  setFilterQuery(store: ActionContext<ITargetAccountsState, any>, data: string): void {
    if (!isEqual(data, store.state.filterQuery)) {
      store.commit("AllAccountList", undefined);
      store.commit("ViewedAccountList", undefined);
      store.commit("NewAccountList", undefined);
    }
    store.commit("FilterQuery", data);
  },

  setNewAccountsQty(store: ActionContext<ITargetAccountsState, any>, data: number): void {
    store.commit("newAccountsQty", data);
  },
  setViewedAccountsQty(store: ActionContext<ITargetAccountsState, any>, data: number): void {
    store.commit("viewedAccountsQty", data);
  },
  setSelectedFilterData(store: ActionContext<ITargetAccountsState, any>, data: object): void {
    store.commit("SelectedFilterData", data);
  },
};

const mutations: MutationTree<ITargetAccountsState> = {
  resetState(state) {
    Object.assign(state, getDefaultTargetState());
  },
  SetAutocomplete(state: ITargetAccountsState, payload: { type: string; data: any }): void {
    state.payload.type = payload.data;
  },
  SetFilterData(state: ITargetAccountsState, payload: { type: string; data: any }): void {
    state.payload.type = payload.data;
  },
  TargetSpec(state: ITargetAccountsState, data: any): void {
    state.targetSpec = data;
  },
  newAccountsQty(state: ITargetAccountsState, data: any): void {
    state.newAccountsQty = data;
  },
  viewedAccountsQty(state: ITargetAccountsState, data: any): void {
    state.viewedAccountsQty = data;
  },
  SelectedFilterData(state: ITargetAccountsState, data: any): void {
    state.selectedFilterData = data;
  },
  AllAccountList(state: ITargetAccountsState, data: any): void {
    state.allAccountList = data;
  },
  ViewedAccountList(state: ITargetAccountsState, data: any): void {
    state.viewedAccountList = data;
  },
  NewAccountList(state: ITargetAccountsState, data: any): void {
    state.newAccountList = data;
  },
  FilterQuery(state: ITargetAccountsState, data: any): void {
    state.filterQuery = data;
  },
  TabChanged(state: ITargetAccountsState, data: string): void {
    state.tabChanged = data;
  },
};

const getters: GetterTree<ITargetAccountsState, IRootState> = {
  Loaded(state: ITargetAccountsState): boolean {
    const { loaded } = state;
    return loaded;
  },
  allAccountList(state: ITargetAccountsState): any | null {
    const { allAccountList }: any = state;
    return allAccountList;
  },
  viewedAccountList(state: ITargetAccountsState): any | null {
    const { viewedAccountList }: any = state;
    return viewedAccountList;
  },
  newAccountList(state: ITargetAccountsState): any | null {
    const { newAccountList }: any = state;
    return newAccountList;
  },
};

const namespaced: boolean = true;

export const targetAccountsStore: Module<ITargetAccountsState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
