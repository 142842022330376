import axios from "axios";
import utils from "@/shared/utils";

export default {
  deleteModel(modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "models/" + modelId + "/", { delete: true })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getTeamModels(teamId: string | null): Promise<object> {
    return new Promise((resolve, reject) => {
      if (teamId !== null) {
        axios
          .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/")
          .then(utils.genericSuccessHandler(resolve, reject))
          .catch(utils.genericErrorHandler(resolve, reject));
      }
    });
  },
};
