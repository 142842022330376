import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae1a1528"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "sidebar-layout" }
const _hoisted_2 = { class: "sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_helper_component = _resolveComponent("helper-component")!
  const _component_footer_bar = _resolveComponent("footer-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_helper_component),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent($data.sideBar)))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["maincontent", [$options.isSidebarExpanded ? 'wideSideBar' : 'narrowSideBar']]),
      style: _normalizeStyle($data.styleMainContent)
    }, [
      _createElementVNode("div", {
        class: "container-fluid main",
        style: _normalizeStyle($data.styleMain)
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ], 6),
    _createVNode(_component_footer_bar)
  ]))
}