import { ActionContext, ActionTree, Module } from "vuex";
import { IRootState } from "@/store";
import apiCalls from "@/pages/measure/apiCalls";

export const state: IMeasureState = {};

export interface IMeasureState {}

export interface IFieldResults {
  file?: [];
  history?: history;
  latest?: latest;
  model_comparision?: modelComparision;
  table?: object;
}

export interface chartData {
  data: [];
  name: string;
}

export interface history {
  bars: chartData[];
  description?: string;
  lines: chartData[];
  name?: string;
  x_labels: string[];
  y_labels: string[];
}

export interface latest {
  bars: chartData[];
  header_text: string;
  lines: chartData[];
  name?: string;
  x_labels: string[];
  y_labels: string[];
  description?: string;
}

export interface modelComparision {
  bars?: chartData[];
  description?: string;
  lines?: chartData[];
  name?: string;
  x_labels: string[];
  y_labels: string[];
}

const actions: ActionTree<IMeasureState, IRootState> = {
  getModelFieldResults(store: ActionContext<IMeasureState, any>, payload: { modelId: string; teamId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getModelFieldResult error", error);
        reject(error);
      };
      const successHandler: (data: any) => void = (data: IFieldResults[]) => {
        const resp: IFieldResults = data[0] as IFieldResults;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      apiCalls.getModelFieldResults(payload.modelId, payload.teamId).then(successHandler).catch(errorHandler);
    });
  },

  getModelLiftSettings(store: ActionContext<IMeasureState, any>, payload: { teamId: string; modelId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        reject(new Error("empty teamId"));
        return;
      }
      if (!payload.modelId) {
        reject(new Error("empty modelId"));
        return;
      }
      apiCalls.getModelLiftSettings(payload.teamId, payload.modelId).then(resolve).catch(reject);
    });
  },

  getModelNLPDetails(store: ActionContext<IMeasureState, any>, payload: { teamId: string; modelId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        reject(new Error("empty teamId"));
        return;
      }
      if (!payload.modelId) {
        reject(new Error("empty modelId"));
        return;
      }
      apiCalls.getModelNLPDetails(payload.teamId, payload.modelId).then(resolve).catch(reject);
    });
  },
};

const namespaced: boolean = true;

export const measureStore: Module<IMeasureState, IRootState> = {
  namespaced,
  state,
  actions,
};
