export enum Type {
  targeting = 1,
}

export interface IModel {
  [key: string]: any;
  _id?: string;
  account_id?: string;
  account_name?: string;
  creation_time?: string;
  description?: string;
  dirty_flag?: boolean;
  last_publish_date?: string;
  last_updated?: string;
  model_state?: string;
  model_type?: string;
  name?: string;
  user_id?: string;
  user_name?: string;
  views_remaining?: number;
  has_parent?: boolean;

  is(t: Type, activeModel?: IModel): boolean;
  blah(): boolean;
}

export class ActiveModel implements IModel {
  _id: undefined;
  account_id: undefined;
  account_name: undefined;
  creation_time: undefined;
  description?: undefined;
  dirty_flag: undefined;
  last_publish_date?: undefined;
  last_updated?: undefined;
  model_state: undefined;
  model_type: undefined;
  name: undefined;
  user_id: undefined;
  user_name: undefined;
  views_remaining: undefined;

  constructor(raw: IModel) {
    Object.assign(this, raw);
  }

  is(t: Type, activeModel?: IModel): boolean {
    switch (t) {
      case Type.targeting:
        if (activeModel && activeModel.model_type === "targeting") {
          return true;
        }
        return false;
    }
    return false;
  }

  blah(): boolean {
    return true;
  }
}
