<template>
  <div id="empty-layout">
    <div class="container-fluid p-0 w-0 main">
      <div v-if="isLoading && $route.path == '/' && !appNeedsLogin" class="d-flex justify-content-center m-0 loading-div">
        <div class="ml-auto">
          <c-circular-progress color="light" size="large" />
        </div>
      </div>
      <slot />
    </div>
    <footer-bar layout="empty" />
  </div>
</template>

<script lang="ts">
import FooterBar from "@/shared/FooterBar.vue";
import { CCircularProgress } from "@enlyft/design-system-v2";
import { mapGetters } from "vuex";
import store from "../store";

export default {
  name: "empty-layout",
  components: {
    FooterBar,
    CCircularProgress,
  },
  data(): {
    isLoading: boolean;
    appNeedsLogin: boolean;
  } {
    return {
      isLoading: true,
      appNeedsLogin: false,
    };
  },
  computed: {
    ...mapGetters("login", {
      loggedInUser: "getUser",
    }),
  },
  mounted() {
    store.subscribe((mutation) => {
      switch (mutation.type) {
        case "setAppReady":
          this.isLoading = false;
          break;
        case "setAppNeedLogin":
          this.appNeedsLogin = !!mutation.payload;
          break;
        default:
          break;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
#empty-layout {
  background: #6cc7d5;
  height: 100%;
}
.main {
  min-height: calc(100vh - 25px);
  background: #6cc7d5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main div {
  width: auto;
}
.footer {
  color: #ffffff !important;
}
</style>
