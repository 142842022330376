import { /*Store, */ Module, ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { IRootState } from "@/store";
import api from "./apiCalls";

const getDefaultState = () => {
  return {
    keywords_topics: undefined,
    tech_topics: undefined,
    intentStatus: undefined,
  };
};

export const state: IIntentState = getDefaultState();

export interface IIntentState {
  keywords_topics: any;
  tech_topics: any;
  intentStatus?: IIntentStatus;
}

export interface IIntentStatus {
  isLoading: boolean;
  error?: string;
}

const actions: ActionTree<IIntentState, IRootState> = {
  getIntentRecommendations(
    store: ActionContext<IIntentState, any>,
    payload: {
      intent_topic_slug?: [];
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("intent recommendations error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("intent recommendations error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };
      if (!payload.intent_topic_slug) {
        errorHandler({ error: "empty parameters for intent recommendations" });
        return;
      }
      api.getIntentRecommendations(payload.intent_topic_slug).then(successHandler).catch(errorHandler);
    });
  },
  getProductRecommendations(
    store: ActionContext<IIntentState, any>,
    payload: {
      product_slug?: [];
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("intent product recommendations error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("intent product recommendations error", "response does not have data", data);
        reject(new Error("response does not have data"));
      };
      if (!payload.product_slug) {
        errorHandler({ error: "empty parameters for intent product recommendations" });
        return;
      }
      api.getProductRecommendations(payload.product_slug).then(successHandler).catch(errorHandler);
    });
  },
  getTechIntentAutocomplete(): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getTechIntentAutocomplete error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      api.getTechIntentAutocomplete().then(successhandler).catch(errorHandler);
    });
  },
  getKeywordIntentAutocomplete(): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getKeywordIntentAutocomplete error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      api.getKeywordIntentAutocomplete().then(successhandler).catch(errorHandler);
    });
  },
  getProductsAutocomplete(): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getProductsAutocomplete error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      api.getProductsAutocomplete().then(successhandler).catch(errorHandler);
    });
  },
  getFacts(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string; fact_type: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getFacts error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for getFacts API call", payload });
        return;
      }
      if (!payload.fact_type) {
        errorHandler({ error: "empty fact_type for getFacts API call", payload });
        return;
      }
      api.getFacts(payload.teamId, payload.modelId, payload.fact_type).then(successhandler).catch(errorHandler);
    });
  },
  getIntent(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getIntent error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for getIntent API call", payload });
        return;
      }
      api.getIntent(payload.teamId, payload.modelId).then(successhandler).catch(errorHandler);
    });
  },
  getIntentTopics(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getIntentTopics error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for getIntentTopics API call", payload });
        return;
      }
      api.getIntentTopics(payload.teamId, payload.modelId).then(successhandler).catch(errorHandler);
    });
  },
  getFinalIntent(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getFinalIntent error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for getFinalIntent API call", payload });
        return;
      }
      api.getFinalIntent(payload.teamId, payload.modelId).then(successhandler).catch(errorHandler);
    });
  },
  getProductProfile(store: ActionContext<IIntentState, any>, payload: { product_slug: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getProductProfile error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      if (!payload) {
        errorHandler({ error: "empty teamId for getProductProfile API call", payload });
        return;
      }
      api.getProductProfile(payload.product_slug).then(successhandler).catch(errorHandler);
    });
  },

  postFacts(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postFacts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postFacts error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for postFacts API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for postFacts API call", payload });
        return;
      }
      api.postFacts(payload.teamId, payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
  postIntent(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postIntent error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postIntent error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for postIntent API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for postIntent API call", payload });
        return;
      }
      api.postIntent(payload.teamId, payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
  postFinalIntent(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postFinalIntent error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postFinalIntent error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for postFinalIntent API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for postFinalIntent API call", payload });
        return;
      }
      api.postFinalIntent(payload.teamId, payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
  postIntentTopics(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postIntentTopics error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postIntentTopics error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for postIntentTopics API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for postIntentTopics API call", payload });
        return;
      }
      api.postIntentTopics(payload.teamId, payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
  postRecommendedProducts(store: ActionContext<IIntentState, any>, payload: { teamId: string; modelId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postRecommendedProducts error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postRecommendedProducts error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty teamId for postRecommendedProducts API call", payload });
        return;
      }
      if (!payload.modelId) {
        errorHandler({ error: "empty modelId for postRecommendedProducts API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for postRecommendedProducts API call", payload });
        return;
      }
      api.postRecommendedProducts(payload.teamId, payload.modelId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
  setKeywords_topics(store: ActionContext<IIntentState, any>, keywords_topics: string[]): void {
    store.commit("Keywords_topics", keywords_topics);
  },
  setTech_topics(store: ActionContext<IIntentState, any>, tech_topics: string[]): void {
    store.commit("Tech_topics", tech_topics);
  },
  setIntentStatus(store: ActionContext<IIntentState, any>, intentStatus: IIntentStatus): void {
    store.commit("IntentStatus", intentStatus);
  },
};

const mutations: MutationTree<IIntentState> = {
  Keywords_topics(state: IIntentState, keywords_topics: string[]): void {
    state.keywords_topics = keywords_topics;
  },
  Tech_topics(state: IIntentState, tech_topics: string[]): void {
    state.tech_topics = tech_topics;
  },
  IntentStatus(state: IIntentState, intentStatus: IIntentStatus): void {
    state.intentStatus = intentStatus;
  },
};

const getters: GetterTree<IIntentState, IRootState> = {
  getKeywords_topics(state: IIntentState): string[] | null {
    const { keywords_topics } = state;
    return keywords_topics || null;
  },
  getTech_topics(state: IIntentState): string[] | null {
    const { tech_topics } = state;
    return tech_topics || null;
  },
};

const namespaced: boolean = true;

export const intentStore: Module<IIntentState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
