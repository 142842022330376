import utils from "@/shared/utils";
import axios from "axios";
import { GENERIC_ERRORS } from "@/shared/constants/errorConstants";

export async function patchUser(userId, payload): Promise<any> {
  const url = `${utils.MODEL_API}v2/users/${userId}/`;
  try {
    const { data } = await axios.patch(url, payload);
    if (data.status !== "success") {
      throw new Error(data.message);
    }

    return data;
  } catch (e: any) {
    throw new Error(e.message);
  }
}

export async function getUser(userId): Promise<any> {
  const url = `${utils.MODEL_API}v2/users/${userId}/`;
  try {
    const { data } = await axios.get(url);
    if (data.status !== "success") {
      throw new Error(data.message);
    }

    if (data.data) {
      return data.data;
    }

    throw new Error(GENERIC_ERRORS.UNKNOWN_ERROR);
  } catch (e: any) {
    throw new Error(e.message);
  }
}
