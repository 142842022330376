import vuexStore from "@/store";
import { useGetters } from "@/shared/utils/storeHelpers";
import { defineStore } from "pinia";
import { AccountExportParams, ContactExportParams, ExportSettings } from "./integrationTypes";
import utils from "@/shared/utils";
import { Integration } from "@/pages/integrations/_integrationTypes";
import { Rights, User } from "@/shared/user";
import { exportToCRM } from "./apiCalls";
import { Exports } from "@/types/exports";
import { downloadFile } from "@/shared/utils/download";
import { AutocompleteValue } from "@/types/autocomplete";

export const availableIntegrations = [
  {
    name: "dynamics",
    label: "Dynamics",
  },
  {
    name: "pipedrive",
    label: "Pipedrive",
  },
  {
    name: "salesforce",
    label: "Salesforce",
  },
  {
    name: "hubspot",
    label: "HubSpot",
  },
  {
    name: "outreach",
    label: "Outreach",
  },
  {
    name: "marketo",
    label: "Marketo",
  },
];

export const useIntegrations = defineStore("integrations", {
  state: () => {
    return {
      integrationsConfigs: {} as { [key: string]: any },
      isExporting: false,
      exportingAccountsTotal: 0,
      exportError: null as string | null,
      selectedPersona: null as any,
      exportSettings: null as ExportSettings | null,
      showExportModal: false,
      modelId: useGetters("model", ["activeModelId"]).activeModelId,
      activeTeamId: useGetters("team", ["activeTeamId"]).activeTeamId,
      loggedInUser: useGetters("login", ["getUser"]).getUser as User,
      activeTeam: useGetters("team", ["activeTeam"]).activeTeam,
      teamIntegrations: useGetters("integrations", ["getTeamIntegrations"]).getTeamIntegrations,
      activeIntegrations: useGetters("integrations", ["activeIntegrations"]).activeIntegrations as Array<Integration>,
      exportActionMessage: "" as string,
      exportActionSuccess: false,
      showOutOfCreditsModal: false as boolean,
      outOfCreditsEntity: "Account" as string,
    };
  },
  actions: {
    /** Gets the config object for any configurations with has_user_configs set to true
     * @param crm_integrations
     */
    getCRMConfig: async function (crm_integrations: any[]) {
      if (crm_integrations.length > 0) {
        // this.activeIntegrations = [];
        crm_integrations.forEach(async (integration: Integration) => {
          // if outreach config seems to be the only integration with this flag
          if (integration?.sync_config?.hasOwnProperty("has_user_configs")) {
            // create a temp object to hold the config
            const integrationConfig = {
              configs: [],
              data: integration,
              isLoading: false,
            };

            // if no matching integration configuration, set loading to the UI can display a spinner
            // loading state will be reset after the api call below
            if (integration?.integration_type && !this.integrationsConfigs.hasOwnProperty(integration.integration_type)) {
              this.integrationsConfigs[integration.integration_type] = {
                configs: [],
                data: integration,
                isLoading: true,
              };
            }
            try {
              const data = await this.getIntegrationConfigs({
                teamId: this.activeTeam.account_id,
                integrationType: integration.integration_type || "",
              });

              if (data && data.length) {
                integrationConfig.configs = data;

                // set the config
                if (integration.integration_type) this.integrationsConfigs[integration.integration_type] = integrationConfig;
              }
            } catch (error: any) {
              console.error("getIntegrationConfigs error", error);
            }
          }

          // const labelIndex = availableIntegrations.findIndex((elem: any) => elem.name === integration?.integration_type);

          // if (labelIndex >= 0) {
          //   this.activeIntegrations.push(availableIntegrations[labelIndex].label);
          // } else {
          //   this.activeIntegrations.push(integration?.integration_type);
          // }
        });
      }
    },
    getCRMIntegrations: async function () {
      if (!this.activeIntegrations) {
        // if no integrations have been loaded yet, get them
        try {
          const data = await this.getTeamCRMIntegrations({ teamId: this.activeTeam?.account_id });

          if (Array.isArray(data["crm_integrations"]) && data["crm_integrations"].length > 0) {
            this.getCRMConfig(data["crm_integrations"]);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        this.getCRMConfig(this.activeIntegrations);
      }
    },
    getIntegrationConfigs: async function ({ teamId, integrationType }: { teamId: string; integrationType: string }) {
      return await vuexStore.dispatch("integrations/getIntegrationConfigs", { teamId, integrationType });
    },

    getTeamCRMIntegrations: async function ({ teamId }: { teamId: string }) {
      return await vuexStore.dispatch("integrations/getTeamCRMIntegrations", { teamId });
    },
    exportAccounts: async function ({ type, selection, spec, totalAccounts, fileName = "" }: AccountExportParams, cb?: () => void) {
      this.isExporting = true;
      this.exportError = null;
      this.exportingAccountsTotal = totalAccounts;
      this.exportActionMessage = "";
      this.exportActionSuccess = false;

      try {
        const data = await exportToCRM({
          teamId: this.activeTeamId,
          modelId: this.modelId,
          integrationType: type.toLowerCase(),
          entityType: "account",
          payload: {
            ...(selection ? { inputs: selection } : spec && { spec: spec }),
          },
        });

        if (data.internal_code == 402) {
          this.showOutOfCreditsModal = true;
          this.outOfCreditsEntity = "Account";
          return;
        }

        if (type === "csv" && typeof data === "string") {
          const accountsData: any = data;
          const blob = new Blob([accountsData], { type: "text/csv" });
          downloadFile(blob, "SavedAccounts" + fileName);
        }
        try {
          const eventName = type === "csv" ? "Download" : "ExportAccount";
          utils.setGACustomEvent(this.loggedInUser, this.activeTeamId, eventName, "self_serve", selection?.length ? selection.length : totalAccounts);
          utils.sendMixPanelEvent(eventName, { Type: "Saved Accounts", Accounts: selection?.length ? selection.length : totalAccounts, CRM: type });
        } catch (ex) {
          console.error("Unknown analytics failure " + ex);
        }
      } catch (error: any) {
        this.exportError = error.message;
        console.error("Account export error: ", error.message);
      } finally {
        this.isExporting = false;
        this.exportingAccountsTotal = 0;
        if (type !== "csv") {
          const accountText = selection?.length ? (selection?.length === 1 ? selection[0].entity_name : `${selection?.length} accounts`) : `${totalAccounts} accounts`;
          const crmName = type;
          this.exportActionMessage = `Exporting ${accountText} to ${utils.getIntegrationName(crmName)}. You’ll be notified once it’s complete.`;
          this.exportActionSuccess = true;
        }
        if (cb) {
          cb();
        }
      }
    },
    async exportContacts({ type, sequenceId, selection, spec, totalContacts, fileName = "" }: ContactExportParams) {
      if (!type) {
        return;
      }
      this.isExporting = true;
      this.exportError = null;
      this.exportActionMessage = "";
      this.exportActionSuccess = false;

      let contacts: Array<Exports.ExportInput> | undefined = undefined;
      if (selection && Object.keys(selection).length) {
        contacts = Object.keys(selection).map((linkedInURL: string) => {
          const contact = selection[linkedInURL];
          const contactObj: any = {
            entity_id: contact.person_id,
            entity_data: {
              buyer_persona_id: selection[linkedInURL]?.buyer_persona || null,
              customer_product_id: this.modelId,
            },
            company_id: contact.company_id,
          };
          if (sequenceId && type.toLowerCase() === "outreach") {
            contactObj.entity_config = {
              sequence_id: sequenceId,
            };
            const mailbox = this.integrationsConfigs["outreach"].configs.find((elem: any) => elem.id === "mailbox_id");
            if (mailbox && mailbox.options && mailbox.options.length) contactObj.entity_config.mailbox_id = mailbox.options[0].value;
          }
          return contactObj;
        });
      }
      try {
        const data = await exportToCRM({
          teamId: this.activeTeamId,
          modelId: this.modelId,
          integrationType: type.toLowerCase(),
          entityType: "contact",
          payload: { ...(contacts?.length ? { inputs: contacts } : spec && { spec: spec }) },
        });

        if (data.internal_code == 402) {
          this.showOutOfCreditsModal = true;
          this.outOfCreditsEntity = "Contact";
          return;
        }

        if (data && data.status && data.status == "failure") {
          throw new Error(data.message);
        }
        if (type === "csv") {
          const contactsData: any = data;
          const blob = new Blob([contactsData], { type: "text/csv" });
          downloadFile(blob, "SavedContacts" + fileName);
        }
        try {
          const eventName = type === "csv" ? "Download" : "ExportContact";
          utils.setGACustomEvent(this.loggedInUser, this.activeTeam, "Export-Contacts", selection?.length ? selection.length : totalContacts, null);
          utils.sendMixPanelEvent(eventName, { Type: "Contacts", ExportedContacts: selection?.length ? selection.length : totalContacts, CRM: type });
        } catch (ex) {
          console.error("Unknown analytics failure " + ex);
        }
        if (contacts?.length) {
          this.showExportModal = true;
          return;
        }
      } catch (error: any) {
        this.exportError = error.message;
        console.error("Contact export error: ", error.message);
      } finally {
        this.isExporting = false;
        if (type !== "csv") {
          const crmName = type;
          this.exportActionMessage = `Exporting ${contacts && contacts?.length > 1 ? contacts?.length + " contacts" : ""} to ${utils.getIntegrationName(crmName)}. You’ll be notified once it’s complete.`;
          this.exportActionSuccess = true;
        }
      }
    },
    getFriendlyIntegrationType: function (integrationType: string) {
      return availableIntegrations.find((elem: any) => elem.name === integrationType)?.label || integrationType;
    },
    async getExportSettings({ teamId, modelId }: { teamId: string; modelId: string }) {
      // return cached data if it exists and matches this team and model
      const cacheKey = `${teamId}/${modelId}`;
      if (this.exportSettings?.cacheKey === cacheKey) return this.exportSettings;

      // otherwise, fetch the data
      const data = (await vuexStore.dispatch("setup/getExportSettings", { teamId, modelId })) as ExportSettings;
      if (data.export_full) {
        data.cacheKey = cacheKey;
        this.exportSettings = data;
      } else {
        // console.log("export settings not found", data);
      }
    },
  },
  getters: {
    crmEnabled(): boolean {
      const { featuresLimit } = useGetters("team", ["featuresLimit"]);
      return !!(this.loggedInUser && featuresLimit && this.loggedInUser.canFeature(Rights.EnableCRM, featuresLimit.value));
    },
  },
});

export const generateFileName = ({ saved_by, dataset_id, start_date, end_date, buyer_persona_id }: Exports.ExportSpec, datasets?: AutocompleteValue[], teamUsers?: AutocompleteValue[], personas?: AutocompleteValue[]) => {
  let fileName: string = "";
  if (buyer_persona_id && personas?.length) {
    const personaName: string | undefined = personas?.find((e: AutocompleteValue) => e.value === buyer_persona_id)?.label;
    if (personaName) fileName += `_${personaName}`;
  }
  if (dataset_id && datasets?.length) {
    const datasetName: string | undefined = datasets.find((e: AutocompleteValue) => e.value === dataset_id)?.label;
    if (datasetName) fileName += `_${datasetName}`;
  }
  if (saved_by && teamUsers?.length) {
    const savedByName: string | undefined = teamUsers.find((e: AutocompleteValue) => e.value === saved_by)?.label;
    if (savedByName) fileName += `_by${savedByName}`;
  }
  if (start_date) {
    fileName += `_from${start_date}`;
    if (end_date) fileName += `to${end_date}`;
  }
  return fileName;
};
