import { IRootState } from "@/store";
import { ActionContext, ActionTree, Module } from "vuex";
import api from "./apicalls";

export const state: IPaymentState = {};

export interface IPaymentState {}

const actions: ActionTree<IPaymentState, IRootState> = {
  getPaymentDetails(store: ActionContext<IPaymentState, any>, payload: { teamId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getPaymentDetails error", error);
        reject(error);
      };
      const successhandler: (data: object) => void = (data: object) => {
        const resp: any = data as any;
        if (resp) {
          resolve(resp);
          return;
        }
      };
      api.getPaymentDetails(payload.teamId).then(successhandler).catch(errorHandler);
    });
  },
  getPaymentLink(store: ActionContext<IPaymentState, any>, teamId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getPaymentLink error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getPaymentLink error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      api.getPaymentLink(teamId).then(successHandler).catch(errorHandler);
    });
  },
  postPayment(store: ActionContext<IPaymentState, any>, payload: { teamId: string; postData: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("postPayment error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("postPayment error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      if (!payload.teamId) {
        errorHandler({ error: "empty tenantId for postPayment API call", payload });
        return;
      }
      if (!payload.postData) {
        errorHandler({ error: "empty postData for postPayment API call", payload });
        return;
      }
      api.postPayment(payload.teamId, payload.postData).then(successHandler).catch(errorHandler);
    });
  },
};

const namespaced: boolean = true;

export const paymentStore: Module<IPaymentState, IRootState> = {
  namespaced,
  state,
  actions,
};
