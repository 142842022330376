import axios from "axios";
import utils from "../../../shared/utils";

export default {
  getVisitorTrackerCode(modelId: string, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/visitor_track/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getExportSettings(modelId: string, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/export_settings/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  postExportSettings(modelId: string, teamId: string, settings: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/export_settings/", settings)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getModelProfile(modelId: string, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/?noaggs=1")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getDatasets(teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/datasets/" + teamId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getDataset(teamId: string, datasetId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/datasets/" + teamId + "/" + datasetId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getCustomFactorGroups(teamId: string, modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/custom_factor_groups/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getAccountsInDataset(teamId: string, datasetId: string, skip: number, limit: number): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/datasets/" + teamId + "/" + datasetId + "/companies" + "/?skip=" + skip + "&limit=" + limit)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  queryAccounts(modelId: string, datasetId: string, saved_by: string, start_date: string, end_date: string, skip: number, limit: number): Promise<object> {
    return new Promise((resolve, reject) => {
      // TBD: probably a better way to do this?
      let urlParam = "";
      if (saved_by) urlParam += "&saved_by=" + saved_by;
      if (start_date) urlParam += "&start_date=" + start_date;
      if (end_date) urlParam += "&end_date=" + end_date;
      const paginationParam = "&limit=" + limit + "&skip=" + skip;
      axios
        .get(utils.MODEL_API + "v2/search/query/accounts/" + modelId + "/?dataset=" + datasetId + urlParam + paginationParam)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  downloadDataset(teamId: string, datasetId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/datasets/" + teamId + "/" + datasetId + "/companies/download/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  /* istanbul ignore next */
  patchCustomFactorGroups(teamId: string, modelId: string, name: string, cfg: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/custom_factor_groups/" + encodeURIComponent(name) + "/", cfg)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  /* istanbul ignore next */
  postCustomFactorGroups(teamId: string, modelId: string, cfg: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/custom_factor_groups/", cfg)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  /* istanbul ignore next */
  deleteCustomFactorGroups(teamId: string, modelId: string, cfgName: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/custom_factor_groups/" + encodeURIComponent(cfgName) + "/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  /* istanbul ignore next */
  getECGAutocomplete(query: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/ecg/autocomplete/?q=" + query, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getCustomLinkedinInfo(teamId: string, modelId: string, tld?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const getURL = tld ? "v2/customer_products/" + teamId + "/" + modelId + "/customizations/custom_urls/company/" + tld + "/" : "v2/customer_products/" + teamId + "/" + modelId + "/customizations/custom_urls/";
      axios
        .get(utils.MODEL_API + getURL)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  patchCustomLinkedinInfo(teamId: string, modelId: string, name: string, cfg: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/custom_urls/" + name + "/", cfg)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getPersonas(teamId: string, modelId: string, showSytemPersona: boolean): Promise<object> {
    return new Promise((resolve, reject) => {
      const prependUrl = !showSytemPersona ? "/?show_system=0" : "/";
      axios
        .get(utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + prependUrl)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getPersonaCount(teamId: string, modelId: string, personaId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/" + personaId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getPersonaAutocomplete(): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/buyer_personas/autocomplete/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  patchBuyerPersona(teamId: string, modelId: string, personaId: string, persona: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/" + personaId + "/", persona)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  postBuyerPersona(teamId: string, modelId: string, persona: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/", persona)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  previewBuyerPersona(persona: object, modelId?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/buyer_personas/query/" + (modelId ? modelId : ""), persona)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  queryBuyerPersona(modelId: string, persona: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/buyer_personas/query/" + modelId + "/", persona)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  previewBuyerPersonaCount(persona: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/buyer_personas/count/", persona)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  deleteBuyerPersona(teamId: string, modelId: string, personaId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/" + personaId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  downloadBuyerPersona(teamId: string, modelId: string, personas?: string[], contacts?: string[]): Promise<object> {
    return new Promise((resolve, reject) => {
      const payload: { buyer_persona_ids?: string[]; person_ids?: string[] } = {};
      payload["buyer_persona_ids"] = personas ? personas : [];
      payload["person_ids"] = contacts ? contacts : [];
      axios
        .post(utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/download/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getSavedContacts(teamId: string, modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/saved_contacts/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getSavedContactsForAccount(teamId: string, modelId: string, company_id: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const params: { company_id?: string } = {};
      if (company_id) {
        params["company_id"] = company_id;
      }
      axios
        .get(utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/saved_contacts/", {
          params,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getPersonaContacts(teamId: string, modelId: string, personaId: string, skip: number, limit: number): Promise<object> {
    return new Promise((resolve, reject) => {
      const params: { skip?: number; limit?: number } = {};
      if (skip) {
        params["skip"] = skip;
      }
      if (limit) {
        params["limit"] = limit;
      }

      const getURL = personaId
        ? utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/" + personaId + "/saved_contacts/"
        : utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/saved_contacts/";

      axios
        .get(getURL, {
          params,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getOwnedContacts(teamId: string, modelId: string, personaId: string, skip: number, limit: number): Promise<object> {
    return new Promise((resolve, reject) => {
      const params: { skip?: number; limit?: number } = {};
      if (skip) {
        params["skip"] = skip;
      }
      if (limit) {
        params["limit"] = limit;
      }

      const getURL = personaId
        ? utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/" + personaId + "/saved_contacts/owned/"
        : utils.MODEL_API + "v2/buyer_personas/" + teamId + "/customer_products/" + modelId + "/saved_contacts/owned/";

      axios
        .get(getURL, {
          params,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  exportContacts(teamId: string, modelId: string, integrationType: string, entityType: string, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/" + integrationType + "/" + entityType + "/export/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getPersonaSettings(teamId: string, modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/buyer_persona_settings/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  postPersonaSettings(teamId: string, modelId: string, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/buyer_persona_settings/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
