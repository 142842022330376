import axios from "axios";
import utils from "@/shared/utils";

export default {
  getModelSummary(modelId: string, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
