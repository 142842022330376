import { /*Store, */ Module, ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { IRootState } from "@/store";
import api from "./apiCalls";
import { IUserTeam } from "@/shared/user";
import utils from "@/shared/utils";

export interface IUserTeams {
  [key: string]: any;
}

export const state: ITeamState = {
  team: undefined,
  credits: undefined,
  featuresLimit: undefined,
  featuresUsage: undefined,
  activeTeam: undefined,
  viewsRemaining: undefined,
  teamUsers: undefined,
  tempEditUserData: undefined,
  lastTeamFeaturesFetch: 0,
  features: undefined,
  lastTeamIdFetch: undefined,
};

export interface ITeamState {
  team: any;
  credits?: number;
  featuresLimit?: IFeaturesLimit;
  featuresUsage?: IFeaturesUsage;
  activeTeam?: IUserTeam;
  viewsRemaining?: number;
  teamUsers: any;
  tempEditUserData: any;
  lastTeamFeaturesFetch: number;
  features: any;
  lastTeamIdFetch?: string;
}

export interface IFeaturesLimit {
  account_redemption: number;
  account_views_limit: number;
  hla_limit: number;
  solutions_limit: number;
  segment_is_b2b: number;
  purchase_is_b2b: number;
  segment_intent: number;
  upload_enrich_accounts: boolean;
  upload_suppress_accounts: boolean;
  intent: boolean;
  all_techs: boolean;
  bundled_purchase_credits: number;
  extra_purchase_credits: number;
  augmented_accounts_limit: number;
  admin_dashboard: boolean;
  segment_is_isv: boolean;
  purchase_is_isv: boolean;
  enrichment_credits: number;
  hla_count_limit: number;
  firmo_views_limit: number;
  saved_search_limit: number;
  suppressed_accounts_limit: number;
  segment_filter: boolean;
  purchase_filter: boolean;
  intent_solutions_limit: number;
}

export interface IFeaturesUsage {
  account_views_limit: number;
  hla_limit: number;
  solutions_limit: number;
  segment_is_b2b: number;
  purchase_is_b2b: number;
  segment_intent: number;
  upload_enrich_accounts: boolean;
  upload_suppress_accounts: boolean;
  intent: boolean;
  all_techs: boolean;
  bundled_purchase_credits: number;
  extra_purchase_credits: number;
  augmented_accounts_limit: number;
  admin_dashboard: boolean;
  segment_is_isv: boolean;
  purchase_is_isv: boolean;
  enrichment_credits: number;
  hla_count_limit: number;
  firmo_views_limit: number;
  saved_search_limit: number;
  suppressed_accounts_limit: number;
  segment_filter: boolean;
  purchase_filter: boolean;
  intent_solutions_limit: boolean;
}

const actions: ActionTree<ITeamState, IRootState> = {
  getTeamUsers(store: any, activeTeam: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        const users: [any] = data as [any];
        store.commit("TeamUsers", data);
        resolve(users);
      };
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("fetching user accounts in team members failed", error);
        reject(error);
      };
      api.getTeamUsers(activeTeam).then(successHandler).catch(errorHandler);
    });
  },
  getLicenseInfo(store: any, activeTeamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        const users: [any] = data as [any];
        resolve(users);
      };
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("fetching user team liscense failed", error);
        reject(error);
      };
      api.getLicenseInfo(activeTeamId).then(successHandler).catch(errorHandler);
    });
  },
  create(store: ActionContext<ITeamState, any>, payload: { accountId: string; user_name: string; user_email: string; role: string; send_email: string }): Promise<object> {
    return api.createUser(payload.accountId, payload.user_name, payload.user_email, payload.role, payload.send_email);
  },
  modify(store: ActionContext<ITeamState, any>, payload: { accountId: string; user_name: string; role: string; userId: string }): Promise<object> {
    return api.modifyUser(payload.accountId, payload.user_name, payload.role, payload.userId);
  },
  delete(store: ActionContext<ITeamState, any>, payload: { accountId: string; userId: string }): Promise<object> {
    return api.deleteUser(payload.accountId, payload.userId);
  },
  getTeamCredits(store: ActionContext<ITeamState, any>, teamId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("teamCredits error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getteamCredits error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      api.getTeamCredits(teamId).then(successHandler).catch(errorHandler);
    });
  },
  setTeamCredits(store: ActionContext<ITeamState, any>, data: number): void {
    store.commit("Credits", data);
  },
  getTeamFeatures(store: ActionContext<ITeamState, any>, teamId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const currentTime = new Date().getTime();
      const fetchDiffTime = 120000;
      // Do not fetch again if last call was made less than 2min before and teamId hasn't change AND we have data
      if (store.state.lastTeamIdFetch === teamId && currentTime - store.state.lastTeamFeaturesFetch < fetchDiffTime && store.state.features) {
        resolve(store.state.features);
        return;
      }
      store.commit("LastTeamFeaturesFetch", currentTime);
      store.commit("LastTeamIdFetch", teamId);
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getTeamFeatures error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: any) => {
        const featuresData = {};
        if (data && data.internal_code && (data.internal_code === 471 || data.internal_code === 474 || data.internal_code === 472)) {
          console.info("JWT expired");
          return;
        }
        if (data && Array.isArray(data) && data.length > 0) {
          const tempFeaturesLimit: any = {};
          const tempFeaturesUsage: any = {};
          data.forEach(function (feature) {
            tempFeaturesLimit[feature.feature_internal_name] = feature.value;
            try {
              if ((window as any).mixpanel) {
                const eventData: any = feature;
                const remaining: number = feature.value - feature.usage;
                Object.assign(eventData, { feature_remaining: remaining });
                const featureName = feature.feature_name;
                Object.assign(featuresData, { [featureName]: feature.value });

                if (feature.feature_type === "limit") {
                  const featureKey = feature.feature_name.replace("Limit", "") + " Remaining";
                  const featureUsage = feature.feature_name.replace("Limit", "") + " Usage";
                  const featureName = feature.feature_name;

                  Object.assign(featuresData, { [featureKey]: remaining });
                  if ((window as any).mixpanel && store.state.activeTeam && store.state.activeTeam.user_id && store.state.activeTeam.account_name) {
                    (window as any).mixpanel.app.get_group("Subscription", store.state.activeTeam.account_name).unset(featureName);
                    (window as any).mixpanel.app.get_group("Subscription", store.state.activeTeam.account_name).unset(featureKey);
                    (window as any).mixpanel.app.get_group("Subscription", store.state.activeTeam.account_name).unset(featureUsage);
                  }
                }
              }
            } catch (ex) {
              console.error("Unknown analytics failure " + ex);
            }
            tempFeaturesUsage[feature.feature_internal_name] = feature.usage;
          });
          try {
            const featuresLimitConst: IFeaturesLimit = tempFeaturesLimit;
            const featuresUsageConst: IFeaturesUsage = tempFeaturesUsage;
            const features: any = { Limit: featuresLimitConst, Usage: featuresUsageConst };
            store.commit("Features", features);
            resolve(features);
          } catch (e: any) {
            console.error("Error getting featureslimit ", e);
          }
          try {
            if (window.location.protocol === "chrome-extension:") return;

            (window as any).mixpanel.app.track("UserFeatures", featuresData);
            const activeTeam = { ...store.state.activeTeam };

            if (store.state.activeTeam !== undefined) {
              const sibUserProperties = {
                user_email: store.state.activeTeam.user_email,
                user_name: store.state.activeTeam.user_name,
                id: store.state.activeTeam.user_id,
                plan: activeTeam.plan_name,
              };
              utils.setMixPanelPropertiesForActiveTeam(activeTeam);
              (window as any).sendinblue.identify(store.state.activeTeam.user_email, featuresData);
              (window as any).sendinblue.track("UserFeatures", sibUserProperties, { data: featuresData });
            }
          } catch (e: any) {
            console.error("Error sending features data to analytics", e);
          }
          return;
        }
        console.error("getTeamFeatures error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      api.getTeamFeatures(teamId).then(successHandler).catch(errorHandler);
    });
  },
  setTeamFeatures(store: ActionContext<ITeamState, any>, features: any): void {
    store.commit("FeaturesLimit", features.Limit);
    store.commit("FeaturesUsage", features.Usage);
  },
  setTeamUsers(store: ActionContext<ITeamState, any>, data: any): void {
    store.commit("TeamUsers", data);
  },
  getTeamPlan(store: ActionContext<ITeamState, any>, teamId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getTeamPlan error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getTeamPlan error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      api.getTeamPlan(teamId).then(successHandler).catch(errorHandler);
    });
  },
  getTeamSummary(store: ActionContext<ITeamState, any>, teamId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("getTeamSummary error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        if (data) {
          resolve(data);
          return;
        }
        console.error("getTeamSummary error", "response is not an object", data);
        reject(new Error("response is not object"));
      };
      api.getTeamSummary(teamId).then(successHandler).catch(errorHandler);
    });
  },
  setActiveTeam(store: ActionContext<ITeamState, any>, team: IUserTeam): void {
    // avoid setting same team twice
    store.commit("ActiveTeam", team);
    if (team?.hasOwnProperty("account_id")) {
      localStorage.setItem("teamId", team.account_id);
      const cookies = $cookies;
      if (cookies) {
        cookies.set("teamid", team.account_id);
      }
    } else {
      localStorage.setItem("teamId", "");
    }
  },
  setActiveTeamSilent(store: ActionContext<ITeamState, any>, team: IUserTeam): void {
    store.commit("ActiveTeamSilent", team);
    if (team?.hasOwnProperty("account_id")) {
      localStorage.setItem("teamId", team.account_id);
    } else {
      localStorage.setItem("teamId", "");
    }
  },
  getTeamCRMIntegrations(store: ActionContext<ITeamState, any>): Promise<object> {
    return new Promise((resolve, reject) => {
      let activeTeamId: string | null = null;
      if (store.state.activeTeam !== undefined) {
        activeTeamId = store.state.activeTeam.account_id;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting crm integrations");
        reject(error);
      };
      api.getCRMIntegrations(activeTeamId).then(successHandler).catch(errorhandler);
    });
  },
  getViewsRemaining(store: ActionContext<ITeamState, any>, payload: { teamId: string; accountId: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorHandler: (error: any) => void = (error: any) => {
        console.error("error getting views remaining");
        reject(error);
      };
      if (!payload.teamId || !payload.accountId) {
        errorHandler({ error: "empty teamId/accountId" });
        return;
      }
      api.getViewsRemaining(payload.teamId, payload.accountId).then(successHandler).catch(errorHandler);
    });
  },
  setViewsRemaining(store: ActionContext<ITeamState, any>, data: number): void {
    store.commit("ViewsRemaining", data);
  },
};

const mutations: MutationTree<ITeamState> = {
  Credits(state: ITeamState, credits: number): void {
    state.credits = credits;
  },
  FeaturesLimit(state: ITeamState, featuresLimit: IFeaturesLimit): void {
    state.featuresLimit = featuresLimit;
  },
  ActiveTeam(state: ITeamState, team: any): void {
    state.activeTeam = team;
  },
  ActiveTeamSilent(state: ITeamState, team: any): void {
    state.activeTeam = team;
  },
  ViewsRemaining(state: ITeamState, viewsRemaining: number): void {
    state.viewsRemaining = viewsRemaining;
  },
  FeaturesUsage(state: ITeamState, featuresUsage: IFeaturesUsage): void {
    state.featuresUsage = featuresUsage;
  },
  TeamUsers(state: ITeamState, users: any): void {
    state.teamUsers = users;
  },
  TempUserEdit(state: ITeamState, tempUserData: any): void {
    state.tempEditUserData = tempUserData;
  },
  LastTeamFeaturesFetch(state: ITeamState, value: number): void {
    state.lastTeamFeaturesFetch = value;
  },
  Features(state: ITeamState, features: any): void {
    state.features = features;
  },
  LastTeamIdFetch(state: ITeamState, value: string): void {
    state.lastTeamIdFetch = value;
  },
};

const getters: GetterTree<ITeamState, IRootState> = {
  credits(state: ITeamState): number | null {
    const { credits } = state;
    return credits || null;
  },
  featuresLimit(state: ITeamState): IFeaturesLimit | null {
    const { featuresLimit } = state;
    return featuresLimit || null;
  },
  activeTeamId(state: ITeamState): string | null {
    const { activeTeam } = state;
    if (!activeTeam) {
      return null;
    }
    return activeTeam.account_id;
  },
  activeTeamName(state: ITeamState): string | null {
    const { activeTeam } = state;
    if (!activeTeam) {
      return null;
    }
    return activeTeam.account_name;
  },
  activeTeam(state: ITeamState): IUserTeams | null {
    const { activeTeam } = state;
    if (!activeTeam) {
      return null;
    }
    return activeTeam;
  },
  remainingViews(state: ITeamState): number | null {
    const { viewsRemaining } = state;
    return viewsRemaining || null;
  },
  featuresUsage(state: ITeamState): IFeaturesUsage | null {
    const { featuresUsage } = state;
    return featuresUsage || null;
  },
};

const namespaced: boolean = true;
export const teamMemberStore: Module<ITeamState, IRootState> = {
  actions,
  namespaced,
  getters,
  mutations,
  state,
};
