import axios from "axios";
import utils from "@/shared/utils";

export default {
  getTeamUsers(teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        //      .get(utils.MODEL_API + "accounts/" + teamId + "/users/")
        .get(utils.MODEL_API + "v2/teams/" + teamId + "/users/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getLicenseInfo(teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "/v2/tenants/" + teamId + "/licenses/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  createUser(accountId: string, user_name: string, user_email: string, role: string, send_email: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/teams/" + accountId + "/users/", {
          email: user_email,
          name: user_name,
          role: role,
          send_email: send_email,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  modifyUser(accountId: string, user_name: string, role: string, user_id: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/teams/" + accountId + "/users/" + user_id + "/", {
          name: user_name,
          role: role,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  deleteUser(accountId: string, user_id: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "v2/teams/" + accountId + "/users/" + user_id + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getTeamCredits(teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/tenants/" + teamId + "/credits/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getAlerts(teamId: string, userId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/teams/" + teamId + "/users/" + userId + "/alerts/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getEmailAlerts(teamId: string, userId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/teams/" + teamId + "/users/" + userId + "/alerts/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  modifyAlert(teamId: string, userId: string, alert_type: string, alert_status: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/teams/" + teamId + "/users/" + userId + "/alerts/" + alert_type + "/", {
          status: alert_status,
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  modifyEmailAlerts(teamId: string, userId: string, alert_status: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/teams/" + teamId + "/users/" + userId + "/alerts/" + alert_status + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
