<template>
  <nav class="navbar navbar-refresh justify-content-center ml-3">
    <span>
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      We have been busy working on the system and have an update for you. Please save any changes and
      <a href="javascript:void(0)" v-on:click="refresh()" class="refresh-link">
        <span class="pb-1">click here</span>
      </a>
      to get the update.
    </span>
  </nav>
</template>
<script lang="ts" setup>
import appStore from "@/store";
const refresh = () => {
  appStore.dispatch("refreshApp");
};
</script>
<style lang="scss" scoped>
.navbar-refresh {
  background-color: #ffe7b5;
  font-size: 0.88rem;
  color: #2a333c;
  text-align: center;
}
.refresh-link {
  color: #27a0b6;
  text-decoration: underline;
}
</style>
