<template>
  <div id="no-layout">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: "no-layout",
};
</script>
