import { PersonaContacts } from "@/types/personaContacts";
import { GENERIC_ERRORS } from "@/shared/constants/errorConstants";
import { SavedContacts } from "./types";
import utils from "@/shared/utils";
import axios, { AxiosResponse } from "axios";
import { AutoComplete } from "@/types/autocomplete";

export async function getContacts({ limit, skip, modelId, spec, signal }: SavedContacts.GetContactsParams): Promise<PersonaContacts.APIResponse | boolean> {
  try {
    const url = `${utils.MODEL_API}v2/search/browse/contacts/${modelId}/?limit=${limit}&skip=${skip}`;

    const response: AxiosResponse = await axios.post(url, { spec }, { signal });

    if (response?.data?.status === "failure") throw new Error(response.data.message);
    if (response?.data?.data) {
      return response.data.data;
    } else throw new Error(GENERIC_ERRORS.UNKNOWN_ERROR);
  } catch (error: any) {
    throw new Error(error?.message ? error.message : error);
  }
}

export async function getFilters({ modelId }: { modelId: string }): Promise<Array<AutoComplete>> {
  const url = `${utils.MODEL_API}v2/search/browse/filters/contacts/${modelId}/`;
  try {
    const response: AxiosResponse = await axios.get(url);
    return response?.data?.data;
  } catch (error: any) {
    throw new Error(error?.message ? error.message : error);
  }
}
