/* istanbul ignore file */

import { Module, ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { IRootState } from "@/store";
import api from "@/pages/integrations/apiCalls";

const getDefaultState = () => {
  return {
    crm_unsaved_fields: undefined,
    activeIntegrations: undefined,
    supportedIntegrations: [
      {
        name: "dynamics",
        label: "Dynamics",
        description: "Dynamics 365 Sales helps you sell smarter, foster relationships, boost productivity, and accelerate performance.",
        linkText: "See on AppSource",
        linkUrl: "https://appsource.microsoft.com/en-us/product/dynamics-365/enlyft.enlyft_account_intelligence",
        canAutoPush: false,
        canCreateDeals: false,
        accountRecordName: "Account",
        contactRecordName: "Contact",
      },
      {
        name: "pipedrive",
        label: "Pipedrive",
        description: "Pipedrive is the first CRM platform made for salespeople, by salespeople.",
        linkText: "See on Pipedrive Marketplace",
        linkUrl: "https://marketplace.pipedrive.com/app/enlyft-account-intelligence/9830df3b42bb7f9d",
        steps: 5,
        canAutoPush: false,
        canCreateDeals: true,
        accountRecordName: "Organization",
        contactRecordName: "Person",
      },
      {
        name: "salesforce",
        label: "Salesforce",
        description: "Salesforce unites your marketing, sales, service, and IT teams from anywhere with one integrated CRM platform.",
        linkText: "See on AppExchange",
        linkUrl: "https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000DdXj6UAF",
        canAutoPush: true,
        canCreateDeals: false,
        accountRecordName: "Account",
        contactRecordName: "Contact",
      },
      {
        name: "hubspot",
        label: "HubSpot",
        description: "Enrich your records with Enlyft company data - automatically and in real-time. Get started in minutes.",
        linkText: "See on HubSpot Marketplace",
        linkUrl: "https://ecosystem.hubspot.com/marketplace/apps/marketing/abm/enlyft-account-insights",
        steps: 4,
        canAutoPush: true,
        canCreateDeals: false,
        accountRecordName: "Company",
        contactRecordName: "Contact",
      },
      {
        name: "marketo",
        label: "Marketo",
        description: "Marketo provides the complete tool kit to deliver winning lead- and account-based marketing automation.",
        linkText: "",
        linkUrl: "",
        steps: 4,
        canAutoPush: false,
        canCreateDeals: false,
        accountRecordName: "Companies",
        contactRecordName: "Leads",
      },
      {
        name: "outreach",
        label: "Outreach",
        description: "The Outreach Sales Engagement Platform helps efficiently and effectively engage prospects to drive more pipeline and close more deals.",
        linkText: "",
        linkUrl: "",
        steps: 4,
        canAutoPush: false,
        canCreateDeals: false,
        accountRecordName: "Account",
        contactRecordName: "Prospect",
      },
    ],
    customIntegrations: [],
    selectedIntegration: undefined,
    teamIntegrations: [],
    setupMode: undefined,
  };
};

export const state: IIntegrationsState = getDefaultState();

export interface IIntegrationsState {
  crm_unsaved_fields: any;
  activeIntegrations: undefined | any;
  supportedIntegrations: any[];
  customIntegrations: string[];
  selectedIntegration: any;
  teamIntegrations: any[];
  setupMode: any;
}

const actions: ActionTree<IIntegrationsState, IRootState> = {
  getTeamCRMIntegrations(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
        if (data && data["crm_integrations"] && Array.isArray(data["crm_integrations"]) && data["crm_integrations"].length > 0) {
          store.commit("ActiveIntegrations", data["crm_integrations"]);
          store.commit("TeamIntegrations", data["crm_integrations"]);
        } else {
          store.commit("ActiveIntegrations", []);
          store.commit("TeamIntegrations", []);
        }
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting crm integrations");
        reject(error);
      };
      api.getCRMIntegrations(payload.teamId).then(successHandler).catch(errorhandler);
    });
  },
  getIntegrationConfigs(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; integrationType: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting crm integrations");
        reject(error);
      };
      api.getIntegrationToken(payload.teamId, payload.integrationType).then(successHandler).catch(errorhandler);
    });
  },
  getPipedriveToken(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; code?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      if (!payload.code) {
        console.error("integrations error", "missing code");
        reject(new Error("missing code"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting pipedrive token");
        reject(error);
      };
      api.fetchIntegrationAccessToken(payload.teamId, "pipedrive", payload.code).then(successHandler).catch(errorhandler);
    });
  },
  uninstallPipedriveApp(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error uninstalling pipedrive");
        reject(error);
      };
      api.deleteIntegrationToken(payload.teamId, "pipedrive").then(successHandler).catch(errorhandler);
    });
  },
  deleteIntegration(store: ActionContext<IIntegrationsState, any>, payload: { teamId: string; integration: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("deleteIntegration error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      if (!payload.integration) {
        console.error("deleteIntegration error", "missing integration name");
        reject(new Error("missing integration name"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error uninstalling integration");
        reject(error);
      };
      api.deleteIntegrationToken(payload.teamId, payload.integration).then(successHandler).catch(errorhandler);
    });
  },
  getDynamicsToken(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; code?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      if (!payload.code) {
        console.error("integrations error", "missing code");
        reject(new Error("missing code"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting dynamics token");
        reject(error);
      };
      api.fetchIntegrationAccessToken(payload.teamId, "dynamics", payload.code).then(successHandler).catch(errorhandler);
    });
  },
  getSalesforceToken(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; code?: string; version?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      if (!payload.code) {
        console.error("integrations error", "missing code");
        reject(new Error("missing code"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting salesforce token");
        reject(error);
      };
      api.fetchIntegrationAccessToken(payload.teamId, "salesforce", payload.code, payload.version).then(successHandler).catch(errorhandler);
    });
  },
  getHubspotToken(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; code?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      if (!payload.code) {
        console.error("integrations error", "missing code");
        reject(new Error("missing code"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting hubspot token");
        reject(error);
      };
      api.fetchIntegrationAccessToken(payload.teamId, "hubspot", payload.code).then(successHandler).catch(errorhandler);
    });
  },
  getOutreachToken(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; code?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      if (!payload.code) {
        console.error("integrations error", "missing code");
        reject(new Error("missing code"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting outreach token");
        reject(error);
      };
      api.fetchIntegrationAccessToken(payload.teamId, "outreach", payload.code).then(successHandler).catch(errorhandler);
    });
  },
  getMarketoToken(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; instance_url?: string; client_id?: string; client_secret?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("getMarketoToken error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("Error getting marketo token");
        reject(error);
      };
      api.fetchMarketoAccessToken(payload.teamId, payload.instance_url, payload.client_id, payload.client_secret).then(successHandler).catch(errorhandler);
    });
  },
  getSetupIntegration(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; integrationsType?: string; requestStep?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting setup");
        reject(error);
      };
      api.getIntegrationSetup(payload.teamId, payload.integrationsType, payload.requestStep).then(successHandler).catch(errorhandler);
    });
  },
  putSetupIntegration(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; requestData: any }): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting setup");
        reject(error);
      };
      api.putSetupIntegration(payload.teamId, payload.requestData).then(successHandler).catch(errorhandler);
    });
  },
  putSetupIntegrationOffering(store: ActionContext<IIntegrationsState, any>, payload: { teamId?: string; integrationsType?: string; offeringId?: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting setup");
        reject(error);
      };
      const postData = { integration_type: payload.integrationsType, offering_id: payload.offeringId };
      api.putSetupIntegration(payload.teamId, postData).then(successHandler).catch(errorhandler);
    });
  },
  putSetupIntegrationPipelines(
    store: ActionContext<IIntegrationsState, any>,
    payload: {
      teamId?: string;
      integrationsType?: string;
      request_step: string;
      sync_config: any;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting setup");
        reject(error);
      };
      const postData = {
        integration_type: payload.integrationsType,
        request_step: payload.request_step,
        sync_config: payload.sync_config,
      };
      api.putSetupIntegration(payload.teamId, postData).then(successHandler).catch(errorhandler);
    });
  },
  putSetupIntegrationSetupSync(store: ActionContext<IIntegrationsState, any>, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("Integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("Error in putSetupIntegrationSetupSync");
        reject(error);
      };
      const postData = {
        integration_type: payload.integrationsType,
        request_step: payload.requestStep,
        ...payload,
      };
      api.putSetupIntegration(payload.teamId, postData).then(successHandler).catch(errorhandler);
    });
  },
  setUnSavedFields(store: ActionContext<IIntegrationsState, any>, crm_unsaved_fields: any): void {
    store.commit("CRM_UnSavedFields", crm_unsaved_fields);
  },
  setActiveIntegrations(store: ActionContext<IIntegrationsState, any>, integrations: any[]): void {
    store.commit("ActiveIntegrations", integrations);
  },
  setTeamIntegrations(store: ActionContext<IIntegrationsState, any>, integrations: any[]): void {
    store.commit("TeamIntegrations", integrations);
  },
  setSupportedIntegrations(store: ActionContext<IIntegrationsState, any>, integrations: any[]): void {
    store.commit("SupportedIntegrations", integrations);
  },
  setCustomIntegrations(store: ActionContext<IIntegrationsState, any>, integrations: string[]): void {
    store.commit("CustomIntegrations", integrations);
  },
  getCRMIntegrationFieldMapping(store: ActionContext<IIntegrationsState, any>, payload: { teamId: string; integration: string; entity: string }): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!payload.teamId) {
        console.error("integrations error", "missing teamId");
        reject(new Error("missing teamId"));
        return;
      }
      if (!payload.integration) {
        console.error("integrations error", "missing integration name");
        reject(new Error("missing integration name"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        resolve(data);
      };
      const errorhandler: (error: any) => void = (error: any) => {
        console.error("error getting crm fields");
        reject(error);
      };
      api.getIntegrationFieldMapping(payload.teamId, payload.integration, payload.entity).then(successHandler).catch(errorhandler);
    });
  },
  setSelectedIntegration(store: ActionContext<IIntegrationsState, any>, integration: any): void {
    store.commit("SelectedIntegration", integration);
  },
  setSetupMode(store: ActionContext<IIntegrationsState, any>, setupMode: any): void {
    store.commit("SetupMode", setupMode);
  },
};

const mutations: MutationTree<IIntegrationsState> = {
  CRM_UnSavedFields(state: IIntegrationsState, crm_unsaved_fields: any): void {
    state.crm_unsaved_fields = crm_unsaved_fields;
  },
  ActiveIntegrations(state: IIntegrationsState, activeIntegrations: string[]): void {
    state.activeIntegrations = activeIntegrations;
  },
  SupportedIntegrations(state: IIntegrationsState, integrations: any[]): void {
    state.supportedIntegrations = integrations;
  },
  CustomIntegrations(state: IIntegrationsState, integrations: string[]): void {
    state.customIntegrations = integrations;
  },
  SelectedIntegration(state: IIntegrationsState, integration: any): void {
    state.selectedIntegration = integration;
  },
  TeamIntegrations(state: IIntegrationsState, integrations: any[]): void {
    state.teamIntegrations = integrations;
  },
  SetupMode(state: IIntegrationsState, setupMode: any[]): void {
    state.setupMode = setupMode;
  },
};

const getters: GetterTree<IIntegrationsState, IRootState> = {
  getUnSavedFields(state: IIntegrationsState): any | null {
    const { crm_unsaved_fields } = state;
    return crm_unsaved_fields || null;
  },
  activeIntegrations(state: IIntegrationsState): string[] | null {
    const { activeIntegrations } = state;
    return activeIntegrations || null;
  },
  getTeamIntegrations(state: IIntegrationsState): any[] | null {
    const { teamIntegrations } = state;
    return teamIntegrations || null;
  },
  getSupportedIntegrations(state: IIntegrationsState): any[] | null {
    const { supportedIntegrations } = state;
    return supportedIntegrations || null;
  },
  getSelectedIntegration(state: IIntegrationsState): any | null {
    const { selectedIntegration } = state;
    return selectedIntegration || null;
  },
  getSetupMode(state: IIntegrationsState): any | null {
    const { setupMode } = state;
    return setupMode || null;
  },
  getCustomIntegrations(state: IIntegrationsState): string[] | null {
    const { customIntegrations } = state;
    return customIntegrations || null;
  },
};

const namespaced: boolean = true;
export const integrationsStore: Module<IIntegrationsState, IRootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
