import { defineStore } from "pinia";
import { useGetters } from "@/shared/utils/storeHelpers";
import api from "@/pages/new_search/apiCalls";
import { captureException } from "@sentry/vue";

export const useDiscover = defineStore("discover", {
  state: () => ({
    filters: {} as { [k: string]: any },
    filtersRawData: [],
    modelId: useGetters("model", ["activeModelId"]).activeModelId,
    accounts: null,
    totalAccounts: null,
    newAccountsCount: null,
    viewedAccountsCount: null,
    totalAccountsCount: null,
    buyerPersonaContactsCount: null,
    chartsData: {},
    datasetsData: [],
    isLoadingFiltersData: true,
    isLoadingDatasetData: true,
  }),
  getters: {
    filtersSpec() {
      const spec = {};

      Object.keys(this.filters).forEach((filterSpec) => {
        if (filterSpec === "technology") {
          if (this.filters[filterSpec].length) {
            spec[filterSpec] = this.filters[filterSpec].map((spec) => {
              const techSpecs = Object.keys(spec);

              return {
                ...spec,
                ...(techSpecs.includes("products") ? { products: spec["products"].map((item) => item.value || item) } : {}),
                ...(techSpecs.includes("categories") ? { categories: spec["categories"].map((item) => item.value || item) } : {}),
                ...(techSpecs.includes("vendors") ? { vendors: spec["vendors"].map((item) => item.value || item) } : {}),
              };
            });
          } else {
            spec[filterSpec] = this.filters[filterSpec];
          }
        } else if (filterSpec === "industry") {
          if (this.filters[filterSpec].length) {
            spec[filterSpec] = this.filters[filterSpec].map((spec) => {
              const industrySpecs = Object.keys(spec);

              return {
                ...spec,
                ...(industrySpecs.includes("industry") ? { industry: spec["industry"].map((item) => (typeof item?.value !== "undefined" ? item.value : item)) } : {}),
                ...(industrySpecs.includes("naics_code") ? { naics_code: spec["naics_code"].map((item) => item.value || item) } : {}),
              };
            });
          } else {
            spec[filterSpec] = this.filters[filterSpec];
          }
        } else if (["location", "AreaName", "SubsidiaryName", "VerticalCategoryName"].includes(filterSpec)) {
          if (this.filters[filterSpec].length) {
            spec[filterSpec] = this.filters[filterSpec].map((spec) => {
              return {
                ...spec,
                values: spec["values"].map((item) => item.value || item),
              };
            });
          }
        } else {
          spec[filterSpec] = this.filters[filterSpec];
        }
      });

      spec["target"] = spec["intent"] || spec["dataset"] || this.isEnterprise ? "saved_accounts" : "all_accounts";

      return spec;
    },
    filtersData() {
      const regionCountryMap = require("@/pages/discover/targetAccounts/regionCountryMap.json");
      if (!Array.isArray(this.filtersRawData) || this.filtersRawData.length === 0) {
        return [];
      }

      // Join the 3 tech discover in a single one
      const techHeadingLabels = {
        products: "Products",
        categories: "Categories",
        vendors: "Vendors",
      };
      const techFilter = this.filtersRawData
        .filter((item) => ["products", "categories", "vendors"].includes(item.name))
        .reduce((arr, item) => {
          if (!arr) {
            arr = {
              ...item,
              name: "technology",
              autocomplete: [],
            };
          }

          arr.autocomplete.push({
            type: "heading",
            label: techHeadingLabels[item.name],
            value: techHeadingLabels[item.name],
          });
          arr.autocomplete.push(
            ...item.autocomplete
              .sort((a, b) => (a.counts > b.counts ? -1 : 1))
              .map((acItem) => ({ ...acItem, type: item.name }))
              .filter((acItem) => acItem.counts > 24),
          );

          return arr;
        }, null);

      // Join the 2 industry discover in a single one
      const industryHeadingLabels = {
        industry: "Industry name",
        naics_code: "NAICS code",
      };

      const industryFilter = this.filtersRawData
        .filter((item) => ["industry", "naics_code"].includes(item.name))
        .reduce((arr, item) => {
          if (!arr) {
            arr = {
              ...item,
              name: "industry",
              autocomplete: [],
            };
          }

          arr.autocomplete.push({
            type: "heading",
            label: industryHeadingLabels[item.name],
            value: industryHeadingLabels[item.name],
          });
          arr.autocomplete.push(...item.autocomplete.map((acItem) => ({ ...acItem, type: item.name })));

          return arr;
        }, null);

      const locationFilter = this.filtersRawData.find((item) => item.name === "location");
      for (const region in regionCountryMap) {
        locationFilter.autocomplete.push({ label: region, value: region });
      }

      const datasetFilter = this.filtersRawData.find((item) => item.name === "dataset");
      datasetFilter.autocomplete.unshift({
        value: "saved_accounts",
        label: "All Saved Accounts",
        name: "All Saved Accounts",
        spec: {
          target: "saved_accounts",
        },
      });

      const intentFilter = this.filtersRawData.find((item) => item.name === "intent") || {};
      if (intentFilter.type) intentFilter.type = "intent";

      const otherFilters = this.filtersRawData.filter((item) => !["products", "categories", "vendors", "industry", "naics_code", "location", "dataset", "intent"].includes(item.name));

      return [techFilter, industryFilter, locationFilter, datasetFilter, intentFilter, ...otherFilters];
    },
  },
  actions: {
    getFiltersData() {
      return new Promise((resolve) => {
        this.isLoadingFiltersData = true;
        api
          .getSearchAutocomplete(this.modelId)
          .then((data) => {
            this.filtersRawData = data;
          })
          .catch((e) => console.error("Error getting discover:", e.message))
          .finally(() => {
            setTimeout(() => (this.isLoadingFiltersData = false), 500);
            resolve("get filters done");
          });
      });
    },
    getAccountsData(spec, strategy) {
      this.totalAccounts = null;
      return new Promise((resolve, reject) => {
        api
          .getSearchQuery(this.modelId, spec, strategy)
          .then((data) => {
            if (!data || !Array.isArray(data) || !(data.length >= 0)) {
              console.error("discover/getSearchQuery invalid data", data);
            } else {
              // this.latestSegmentQuery = { ...querySignature };
              this.accounts = data[0].accounts;
              this.totalAccounts = data[0].num_accounts;

              resolve(data[0]);
            }
          })
          .catch((err) => {
            if (err && err.message === "Only one request allowed at a time.") {
              return;
            }
            this.totalAccounts = 0;
            this.accounts = null;
            console.error("discover/getSearchQuery error", err);
            captureException(new Error("Query API failed. Error while loading segment data"));
            if (err && err.message !== "Only one request allowed at a time.") {
              reject("Error while loading segment data");
              throw "Segments query API failure";
            }
          });
      });
    },
    getChartsData(spec) {
      return new Promise((resolve, reject) => {
        const errorHandler: (error: object) => void = (error: object) => {
          console.error("getSearchAggregate error", error);
          reject(error);
        };
        const successHandler: (data: object) => void = (data: object) => {
          if (data) {
            this.chartsData = {
              ...this.chartsData,
              ...data,
            };
            resolve(data);
            return;
          }
          console.error("getSearchAggregate error", "response is not an object", data);
          reject(new Error("invalid response"));
        };

        if (!this.modelId) {
          errorHandler({ error: "empty parameters for getSearchAggregate API call" });
          return;
        }
        api.getSearchAggregate(this.modelId, { spec: spec }).then(successHandler).catch(errorHandler);
      });
    },
    getAllSearches(shouldRedirect?: boolean) {
      this.isLoadingDatasetData = true;
      return new Promise((resolve, reject) => {
        const errorHandler: (error: object) => void = (error: object) => {
          console.error("getSearchAggregate error", error);
          reject(error);
        };
        const successHandler: (data: object) => void = (data: object) => {
          if (data) {
            this.datasetsData = data;
            resolve(data);
            return;
          }
          console.error("getAllSearches error", "response is not an object", data);
          reject(new Error("invalid response"));
        };

        if (!this.modelId) {
          errorHandler({ error: "empty parameters for getAllSearches API call" });
          return;
        }
        api
          .getAllSearches(this.modelId)
          .then(successHandler)
          .catch(errorHandler)
          .finally(() => {
            if (!shouldRedirect) {
              setTimeout(() => (this.isLoadingDatasetData = false), 500);
            }
          });
      });
    },
    getOwnedSearches(shouldRedirect?: boolean) {
      this.isLoadingDatasetData = true;
      return new Promise((resolve, reject) => {
        const errorHandler: (error: object) => void = (error: object) => {
          console.error("getOwnedSearches error", error);
          reject(error);
        };
        const successHandler: (data: object) => void = (data: object) => {
          if (data) {
            this.datasetsData = data;
            resolve(data);
            return;
          }
          console.error("getOwnedSearches error", "response is not an object", data);
          reject(new Error("invalid response"));
        };

        if (!this.modelId) {
          errorHandler({ error: "empty parameters for getOwnedSearches API call" });
          return;
        }
        api
          .getOwnedSearches(this.modelId)
          .then(successHandler)
          .catch(errorHandler)
          .finally(() => {
            if (!shouldRedirect) {
              setTimeout(() => (this.isLoadingDatasetData = false), 500);
            }
          });
      });
    },
    getSearchCount(spec: any, datasetId?: string, modelId?: string) {
      return new Promise((resolve, reject) => {
        const errorHandler: (error: object) => void = (error: any) => {
          if (error && error.message !== "Only one request allowed at a time.") {
            console.error("getSearchCount error", error);
          }
          reject(error);
        };
        const successHandler: (data: object) => void = (data: any) => {
          if (data) {
            this.totalAccounts = data.data[0].num_accounts;

            if (data?.additionl_data?.length) {
              const { not_done_num_accounts, done_num_accounts, all_num_accounts } = data.additionl_data[0];

              if (not_done_num_accounts || not_done_num_accounts === 0) {
                this.newAccountsCount = not_done_num_accounts;
              } // new
              if (done_num_accounts || done_num_accounts === 0) {
                this.viewedAccountsCount = done_num_accounts;
              } // viewed
              if (all_num_accounts || all_num_accounts === 0) {
                this.totalAccountsCount = all_num_accounts;
              } // all
            }
            if (data?.data[0].bp_count) {
              // sets the number of contacts matching the buyer persona. Used in the accounts table
              this.buyerPersonaContactsCount = data.data[0].bp_count;
            }

            resolve(data);
            return;
          }
          console.error("getSearchCount error", "response is not an object", data);
          reject(new Error("invalid response"));
        };
        if (!modelId || !spec) {
          errorHandler({ error: "empty parameters for getSearchCount API call" });
          return;
        }
        api
          .getSearchCount(modelId || this.modelId, { spec }, datasetId)
          .then(successHandler)
          .catch(errorHandler);
      });
    },
    getSearchSpec(modelId: string, target: string, id: string): Promise<object> {
      return new Promise((resolve, reject) => {
        const errorHandler: (error: object) => void = (error: object) => {
          console.error("getSearchSpec error", error);
          reject(error);
        };
        const successHandler: (data: object) => void = (data: object) => {
          if (data) {
            resolve(data);
            return;
          }
          console.error("getSearchSpec error", "response is not an object", data);
          reject(new Error("invalid response"));
        };

        if (!modelId) {
          errorHandler({ error: "empty modelId for getSearchSpec API call" });
          return;
        }
        if (!target) {
          errorHandler({ error: "empty target for getSearchSpec API call" });
          return;
        }
        if (!id) {
          errorHandler({ error: "empty id for getSearchSpec API call" });
          return;
        }
        api.getSearchSpec(modelId, target, id).then(successHandler).catch(errorHandler);
      });
    },
    resetTotalAccounts() {
      this.totalAccounts = null;
    },
  },
});
