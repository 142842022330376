const GENERIC_ERRORS = {
  NO_RESPONSE: "No response from server",
  UNKNOWN_ERROR: "Unknown error",
  NO_MATCHING_PERSONAS: "No matching personas",
  UNABLE_TO_EXPORT_CONTACTS: "We were unable to export the contacts.",
  NO_FILTERS: "No filters found",
  NO_SEARCH_RESULTS: "No search results found",
  EXPORT_ERROR: "There was a problem exporting. Please try again or contact us.",
  FILTERS_ERROR: "There was a problem loading filters. Please try again or contact us.",
  NO_BUYING_SIGNALS: "No buying signals found",
  AUTO_EMAIL_ERROR: "There was a problem generating email template. Please try again or contact us.",
};

const ACCOUNT_ERRORS = {
  ACCOUNTS_LOADING_ERROR: "There was a problem loading accounts. Please try again or contact us.",
  ACCOUNT_LOADING_PROFILE_ERROR: "Error while loading company profile",
  ACCOUNT_SAVING_ERROR: "There was a problem saving this account. Please try again or contact us.",
  ACCOUNT_SAVING_NO_CREDITS_ERROR: "Not enough credits to save this account.",
  ACCOUNT_EXPORTING_ERROR: "There was a problem exporting this account. Please try again or contact us.",
  ACCOUNT_SUPPRESSING_ERROR: "There was a problem suppressing this account. Please try again or contact us.",
  ACCOUNT_UNSUPPRESSING_ERROR: "There was a problem unsuppressing this account. Please try again or contact us.",
  ACCOUNT_MARK_AS_VIEWED_LIMIT_ERROR: "You have reached the monthly limit of accounts Marked as Viewed.",
  ACCOUNT_MARK_AS_VIEWED_ERROR: "There was a problem marking this account as viewed. Please try again or contact us.",
  ACCOUNT_MARK_AS_NEW_ERROR: "There was a problem marking this account as new. Please try again or contact us.",
};

const CONTACT_ERRORS = {
  CONTACTS_LOADING_ERROR: "There was a problem loading contacts. Please try again or contact us.",
  CONTACT_SAVING_ERROR: "We were unable to save the contact. Please try again.",
  CONTACT_EXPORTING_ERROR: "We were unable to export the contact. Please try again.",
};

const BUYING_SIGNAL_ERRORS = {
  BUYING_SIGNALS_NO_FILTERS: "There was a problem loading buying signal filters. Please try again or contact us.",
  NOT_ENABLED: "Buying signals are not enabled for this subscription.",
};

const SEARCH_ERRORS = {
  SAVE_SEARCH_LIMIT_REACHED: "You have reached the search saving limit for your plan. Please contact us to learn more",
};

const EMAIL_GENERATOR_ERRORS = {
  AUTO_EMAIL_ERROR: "There was a problem generating email template. Please try again or contact us.",
  NOT_ENABLED: "Email Generator feature is not enabled for this subscription.",
  PROMPT_SAVING_ERROR: "We were unable to save the changes. Please try again.",
};

const GROWTH_ERRORS = {};

const SOLUTION_OUTREACH_ERRORS = {
  NOT_ENABLED: "Solution & Outreach feature is not enabled for this subscription.",
  SOLUTION_DETAILS_ERROR: "There was a problem generating solution details. Please try again or contact us.",
  CUSTOM_INTEL_ERROR: "There was a problem generating the content. Please try again or contact us.",
};

export { ACCOUNT_ERRORS, BUYING_SIGNAL_ERRORS, CONTACT_ERRORS, EMAIL_GENERATOR_ERRORS, GENERIC_ERRORS, GROWTH_ERRORS, SEARCH_ERRORS, SOLUTION_OUTREACH_ERRORS };
