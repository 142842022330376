import axios from "axios";
import utils from "@/shared/utils";

const onlyLatestSearchQuery = utils.postAndCancelPrevious();
const onlyLatestSearchCount = utils.postAndCancelPrevious();

export default {
  getSegmentAutocomplete(modelId: string, teamId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/autocomplete/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getSegmentById(modelId: string, teamId: string, segmentId: string, spec: object): Promise<object> {
    return new Promise((resolve, reject) => {
      const post_data: any = { ...spec };
      if (post_data && post_data.spec && post_data.spec.countryState) {
        delete post_data.spec.countryState;
      }
      axios
        .post(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/" + segmentId + "/query/", post_data)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getSegmentByIdCount(modelId: string, teamId: string, segmentId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/" + segmentId + "/count/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  saveSegment(params: { modelId: string; teamId: string; ownerEmail?: string; spec: object }): Promise<object> {
    return new Promise((resolve, reject) => {
      const post_data: any = { ...params.spec };
      if (post_data && post_data.spec && post_data.spec.countryState) {
        delete post_data.spec.countryState;
      }
      axios
        .post(utils.MODEL_API + "v2/segments/" + params.teamId + "/" + params.modelId + "/", post_data)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  patchSegment(modelId: string, teamId: string, segmentId: string, patchData: object): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .patch(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/" + segmentId + "/", patchData)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  deleteSegment(modelId: string, teamId: string, segmentId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .delete(utils.MODEL_API + "v2/segments/" + teamId + "/" + modelId + "/" + segmentId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  // v2 APIS
  getSearchAutocomplete(modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/search/filters/" + modelId + "/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getSearchCount(modelId: string, post_data: any, datasetId?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      if (!post_data || !post_data.spec) {
        reject(new Error("invalid post_data"));
        return;
      }
      const spec: any = { ...post_data.spec };
      if (spec && spec.countryState) {
        delete spec.countryState;
      }
      const payload: any = { spec };
      if (datasetId) payload.dataset_id = datasetId;
      onlyLatestSearchCount(utils.MODEL_API + "v2/search/count/" + modelId + "/", payload)
        .then(utils.addtDataSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getSearchQuery(modelId: string, spec: object, strategy?: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const post_data: any = { ...spec };
      if (post_data && post_data.spec && post_data.spec.countryState) {
        delete post_data.spec.countryState;
      }
      if (strategy) {
        post_data.strategy = strategy;
      }
      const successHandler: (response: any) => void = (response: any) => {
        if (response) {
          if (response?.data?.internal_code === 500) {
            // response is a 200, but the internal code is actually a 500
            reject(new Error(response.data.message));
            return;
          }
          if (response?.data?.data) {
            resolve(response.data.data);
          } else if (response?.data) {
            resolve(response.data);
          } else {
            resolve(response);
          }
          return;
        }
        console.error("getSearchQuery error", "response is not a segment", response);
        reject(new Error("invalid response"));
      };
      onlyLatestSearchQuery(utils.MODEL_API + "v2/search/query/" + modelId + "/", post_data)
        .then(successHandler)
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getSearchAggregate(modelId: string, spec: object): Promise<object> {
    return new Promise((resolve, reject) => {
      const post_data: any = { ...spec };
      if (post_data && post_data.spec && post_data.spec.countryState) {
        delete post_data.spec.countryState;
      }
      axios
        .post(utils.MODEL_API + "v2/search/aggregate/" + modelId + "/", post_data)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getAllSearches(modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/search/unified/list/" + modelId + "/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getOwnedSearches(modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/search/unified/list/" + modelId + "/owned/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },

  getSearchSpec(modelId: string, target: string, id: string): Promise<object> {
    return new Promise((resolve, reject) => {
      const param = target === "all_accounts" ? "?dataset_id=" + id : "?segment_id=" + id;
      axios
        .get(utils.MODEL_API + "v2/search/info/" + modelId + "/" + param)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
