<template>
  <nav class="navbar navbar-refresh justify-content-center">
    <span>
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      Improve your Enlyft experience: You're using a browser version we no longer support. Try one of these options to have a better experience with Enlyft:
      <a href="https://www.google.com/chrome/index.html" target="_blank" class="refresh-link">Google Chrome</a>,
      <a href="https://www.mozilla.org/en-US/firefox/new" target="_blank" class="refresh-link">Mozilla Firefox</a>, <a href="https://www.apple.com/safari" target="_blank" class="refresh-link">Apple Safari</a>,
      <a href="https://www.microsoft.com/en-us/edge" target="_blank" class="refresh-link">Microsoft Edge</a>.
      <span class="pb-1"><a href="https://app.enlyft.com/unsupported-browser.html" class="refresh-link">Click here </a></span>for details.
    </span>
  </nav>
</template>
<style lang="scss" scoped>
.navbar-refresh {
  background-color: #ffe7b5;
  font-size: 0.88rem;
  color: #2a333c;
  text-align: center;
}
.refresh-link {
  color: #27a0b6;
  text-decoration: underline;
}
</style>
