import axios from "axios";
import utils from "@/shared/utils";
const onlyLatestpostRecommendedProducts = utils.postAndCancelPrevious();

export default {
  postFacts(teamId: string, modelId: string, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/facts/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  postFinalIntent(teamId: string, modelId: string, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/intent/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  postIntent(teamId: string, modelId: string, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/recommendations/intent/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  postIntentTopics(teamId: string, modelId: string, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .post(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/intent_topics/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  postRecommendedProducts(teamId: string, modelId: string, payload: any): Promise<object> {
    return new Promise((resolve, reject) => {
      onlyLatestpostRecommendedProducts(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/recommendations/products_for_url/", payload)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getTechIntentAutocomplete(): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/ecg/technology_intent_topics/autocomplete/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getKeywordIntentAutocomplete(): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/ecg/keyword_intent_topics/autocomplete/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getProductsAutocomplete(): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/ecg/products/autocomplete/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getFacts(teamId: string, modelId: string, fact_type: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/facts/?fact_types=" + fact_type)
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getIntent(teamId: string, modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/recommendations/intent/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getIntentTopics(teamId: string, modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/customizations/intent_topics/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getFinalIntent(teamId: string, modelId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/customer_products/" + teamId + "/" + modelId + "/recommendations/final_intent/")
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getProductProfile(product_slug: string): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(utils.MODEL_API + "v2/ecg/products/" + product_slug + "/profile/", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getIntentRecommendations(intent_topic_slug?: []): Promise<object> {
    return new Promise((resolve, reject) => {
      const params: { intent_topic_slug?: string } = {};
      if (intent_topic_slug && intent_topic_slug.length > 0) {
        params["intent_topic_slug"] = intent_topic_slug.join(",");
      }
      axios
        .get(utils.MODEL_API + "v2/ecg/recommendations/", { params })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
  getProductRecommendations(product_slug?: []): Promise<object> {
    return new Promise((resolve, reject) => {
      const params: { product_slug?: string } = {};
      if (product_slug && product_slug.length > 0) {
        params["product_slug"] = product_slug.join(",");
      }
      axios
        .get(utils.MODEL_API + "v2/ecg/product_recommendations/", { params })
        .then(utils.genericSuccessHandler(resolve, reject))
        .catch(utils.genericErrorHandler(resolve, reject));
    });
  },
};
